import { Box, CircularProgress, Grid, Link, Tooltip } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../../interceptors/AxiosInterceptor';
import GenericTable from '../../edvenswa.emportal.common/pages/GenericTable';
import { AUTH_SECURE_USER_DETAILS_LS_LEY } from '../../edvenswa.emportal.auth/constants/Constants';

const UserScreenAppraisal = (props) => {

    const user = JSON.parse(sessionStorage.getItem(AUTH_SECURE_USER_DETAILS_LS_LEY));
    const location = useLocation();
    const [filteredElements, setFilteredElements] = useState([]);
    const [title, setTitle] = useState('');
    const [startDate, setStartDate] = useState(props.user.roles[0] === "Admin" ? location.state.startDate : moment(new Date()).startOf("month").format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(props.user.roles[0] === "Admin" ? location.state.endDate : moment(new Date()).endOf("month").format("YYYY-MM-DD"));
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const CustomNameComponent = (props) => {
        const { firstname, lastname, associateId } = props.data;
        return (
            <Tooltip title="User Overview">
                <Link
                    sx={{ cursor: "pointer" }}
                    underline="hover"
                    onClick={() => {
                        setTitle(`${firstname} ${lastname}`)
                        navigate("/home/ratings/UserAppraisalForm", {
                            state: {
                                projectManager: props?.data?.projectManager,
                                type: "User",
                                astId: associateId,
                                fullname: `${props.data.firstname} ${props.data.lastname}`,
                                username: `${props.data.username}`,
                                project: props.data.project,
                                ratings: JSON.parse(props.data.ratingsObj),
                                comments: props.data.comments,
                                startDate: moment(props.data.startDate, 'YYYY,MM,DD').format("YYYY-MM-DD"),
                                endDate: moment(props.data.endDate, 'YYYY,MM,DD').format("YYYY-MM-DD")
                            },
                        });
                    }}
                >
                    {firstname} {lastname}
                </Link>
            </Tooltip>
        );
    };

    const calculateRatingAverage = (props) => {
        return <>{Math.floor(props.data.ratings*100)/100}</>
    }

    const columnDefs = [
        {
            field: "associateId",
            headerName: "Ast ID",
            type: "number",
            cellStyle: { textAlign: 'right' },
            // valueGetter: (params) =>
            //     params?.data?.user ? params?.data?.user?.associateId : "",
            flex: 0.5
        },
        {
            headerName: "Associate Name",
            filter: true,
            cellRenderer: CustomNameComponent,
            width: 200
        },
        {
            field: "startDate",
            headerName: "Start Date",
            type: "text",
            valueFormatter: (startDate) =>
                moment(startDate.value, "YYYY,MM,DD").format("DD-MMM-YYYY"),
            flex: 0.5
        },
        {
            field: "endDate",
            headerName: "End Date",
            type: "text",
            valueFormatter: (startDate) =>
                moment(startDate.value, "YYYY,MM,DD").format("DD-MMM-YYYY"),
            flex: 0.5
        },
        {
            field: "comments",
            headerName: "Comments",
            type: "text",
            flex: 1,
            valueGetter: (params) => params.data.comments ? params.data.comments : "No Feedback Available"
        },
        {
            field: "ratings",
            headerName: "PERF Rating",
            cellEditorPopup: true,
            filter: true,
            cellStyle: { textAlign: 'right' },
            cellRenderer: calculateRatingAverage,
            flex: 0.5
        },
    ];


    let sD1 = moment(startDate).startOf('month').format('YYYY-MM-DD');
    let eD1 = moment(sD1).startOf('month').add(14, 'days').format('YYYY-MM-DD');
    let sD2 = moment(startDate).startOf('month').add(15, 'day').format('YYYY-MM-DD');
    let eD2 = moment(sD2).endOf('month').format('YYYY-MM-DD')
    let firstBiweeklyObj = {
        associateId: user?.associateId,
        "comments": null,
        "ratingsObj": null,
        "username": null,
        "firstname": `${user.firstname}`,
        "lastname": `${user.lastname}`,
        "project": "EmPortal",
        "projectManager": {
            "associateId": 1065,
            "username": "----",
            "fullname": "Self Rating",
            "firstname": null,
            "lastname": null,
            "dateOfJoining": null,
            "dateOfBirth": null,
            "designation": null,
            "status": null,
            "logo": null,
            "mobile": null,
            "roleId": null,
            "tenant": null,
            "email": null,
            "projects": null,
            "lastLoginTimeStamp": null,
            "projectTitles": null,
            "role": null
        },
        "ratings": 2.5,
        "score": 0.0,
        "Associate Name": `${user.firstname} ${user.lastname}`, startDate: sD1, endDate: eD1, ratings: 0
    }
    let secondBiweeklyObj = {
        associateId: user?.associateId,
        "comments": null,
        "ratingsObj": null,
        "username": null,
        "firstname": `${user.firstname}`,
        "lastname": `${user.lastname}`,
        "project": "EmPortal",
        "projectManager": {
            "associateId": 1065,
            "username": "sasikala@edvenswatech.com",
            "fullname": "-------",
            "firstname": null,
            "lastname": null,
            "dateOfJoining": null,
            "dateOfBirth": null,
            "designation": null,
            "status": null,
            "logo": null,
            "mobile": null,
            "roleId": null,
            "tenant": null,
            "email": null,
            "projects": null,
            "lastLoginTimeStamp": null,
            "projectTitles": null,
            "role": null
        },
        "ratings": 2.5,
        "score": 0.0,
        "Associate Name": `${user.firstname} ${user.lastname}`, startDate: sD2, endDate: eD2, ratings: 0
    }

    useEffect(() => {
        setLoading(true);
        // if (location?.state?.type) {
        //     setFilteredElements(location.state.userRatings)
        //     setLoading(false)
        // }
        // else {


        axiosInstance.put("/user/getRatingsByUser", {
            startDate: startDate,
            user: { username: location?.state?.username ?? user?.username },
            endDate: endDate
        }).then(res => {
            if (location.state && (user.roles[0] === "Admin" || (user.roles[0] === "Project Manager" && user.username !== location.state.username))) {
                setFilteredElements(res.data)
            }
            else {
                if (res.data.length === 0) {
                    let arr = [firstBiweeklyObj, secondBiweeklyObj];
                    setLoading(false);
                    setFilteredElements(arr);
                }
                else if (res.data.length === 1) {
                    const providedFirst = res.data[0].startDate === moment(startDate).startOf('month').format('YYYY-MM-DD');
                    let arr = [providedFirst ? res.data[0] : firstBiweeklyObj, !providedFirst ? res.data[0] : secondBiweeklyObj];
                    setLoading(false);
                    setFilteredElements(arr)
                }
                else {
                    setLoading(false);
                    setFilteredElements(res.data)
                }
            }

            setLoading(false)
        }).catch(() => {
            setLoading(false);
        })


        // }

    }, [startDate, endDate])
    return (
        // loading ? (
        //     <Grid sx={{ textAlignLast: "center" }} item xs={12}>
        //         <Box style={{ paddingTop: "50px", paddingBottom: "40px" }}>
        //             <CircularProgress />
        //         </Box>
        //     </Grid>) :
        //     <div
        //         className="ag-theme-alpine" // applying the grid theme
        //         style={{
        //             height: 419,
        //             width: "100%",
        //             fontFamily: "Lucida Grande",
        //         }}
        //     >
        //         <AgGridReact
        //             rowData={filteredElements}
        //             columnDefs={columnDefs}
        //             domLayout="normal"
        //             defaultColDef={{
        //                 filter: true,
        //                 width: 120,
        //                 headerClass: "custom-header",
        //             }}
        //         />
        //     </div>
        <>
            <GenericTable
                type={"User Ratings"}
                colDefs={columnDefs}
                elements={filteredElements}
                filteredElements={filteredElements}
                setFilteredElements={setFilteredElements}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                loading={loading}
                displayAdd={false}
                displayHeader={true}
            />

        </>
    )
}

export default UserScreenAppraisal
