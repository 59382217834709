import React from 'react'
import gifImage from '../src/assets/5Cs.gif';
import AuthLogo from "../src/assets/5Clogo.png";
const GenericLoader = () => {
    return (
        <div>
            <img
                src={gifImage}
                alt={AuthLogo}
                style={{ position: 'absolute' }}
                width={"65px"}
                height={"65px"}
                loading="lazy"
            />
        </div>
    )
}

export default GenericLoader
