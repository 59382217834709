import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";

export const TaskDialog = (props) => {
  const { startDate, endDate, astId, open, setOpen } = props;
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .put(`/task/getAllByUser?astId=${astId}`, { startDate, endDate })
      .then((res) => {
        setTasks(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const colDefs = [
    { field: "project", headerName: " Project Name", filter: true, flex: 1 },
    { field: "taskName", headerName: "Task Name", filter: true, flex: 1 },
    {
      field: "description",
      headerName: "Description",
      filter: true,
      flex: 1,
    },
    { field: "category", headerName: "Category", filter: true, width: 180 },
    {
      field: "startDate",
      headerName: "Start Date",
      filter: true,
      flex: 1,
      valueFormatter: (startDate) =>
        moment(startDate.value, "YYYY,MM,DD").format("DD-MM-YYYY"),
    },
    {
      field: "endDate",
      headerName: "End Date",
      filter: true,
      flex: 1,
      valueFormatter: (endDate) =>
        moment(endDate.value, "YYYY,MM,DD").format("DD-MM-YYYY"),
    },
    {
      field: "estimatedHours",
      headerName: "Estimated Hours",
      filter: true,
      flex: 1,
      valueFormatter: (p) => {
        if (p.value !== null) {
          return `${Math.floor(p.value / 3600)}:${Math.floor(
            (p.value % 3600) / 60
          )}`;
        } else {
          return `0`;
        }
      },
      cellStyle: { textAlign: "right" },
    },
  ];
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={() => setOpen(false)}>
      <DialogTitle
        sx={{
          textAlign: "center",
          color: "white",
          backgroundColor: "teal",
          padding: "5px",
        }}
      >
        Tasks
      </DialogTitle>
      <DialogContent sx={{ marginTop: "5px" }}>
        {loading ? (
          <Grid textAlign={"center"}>
            <CircularProgress />
          </Grid>
        ) : tasks && tasks.length > 0 ? (
          <div
            className="ag-theme-alpine"
            style={{ height: "400px", width: "100%" }}
          >
            <AgGridReact
              rowData={tasks}
              columnDefs={colDefs}
              domLayout="normal"
              defaultColDef={{
                filter: true,
                headerClass: "custom-header",
              }}
            />
          </div>
        ) : (
          <Grid>
            <Typography textAlign={"center"} color="textSecondary">
              No tasks found.
            </Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Grid alignContent={"end"}>
          <Button
            size="small"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
