import { Close, Search } from "@mui/icons-material";
import { Alert, Box, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
 
export default function SearchFilter(props) {
    const {
        elements,
        setFilteredElements,
        setSearchTerm,
        searchTerm,
        setPage,
        state = {}, // Default to an empty object if `state` is undefined
        setState = () => {}, // Default to a no-op function if `setState` is undefined
    } = props;
 
    const [value, setValue] = useState(state?.searchTerm || ""); // Initialize value from state
    const [noResults, setNoResults] = useState(false);
 
    const handleChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const inputValue = event.target.value.toLowerCase();
 
        setValue(event.target.value); // Update state directly
        if (inputValue) {
            setFilteredElements([]);
            setState({ ...state, searchTerm: inputValue });
            setSearchTerm(inputValue);
            setPage(1);
        } else {
            setSearchTerm("");
            setFilteredElements(elements);
            setNoResults(false);
        }
    };
 
    return (
        <>
            {/* <TextField
                name="search"
                label="Search"
                size="small"
                InputLabelProps={{
                    shrink: true,
                    style: { fontSize: "16px", fontFamily: "Lucida Grande" },
                }}
                value={value}
                InputProps={{
                    style: { font: "small-caption", fontFamily: "Lucida Grande" },
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <Close
                                fontSize="small"
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                    // Clear all relevant states
                                    setValue(""); // Clear text field
                                    setSearchTerm("");
                                    setFilteredElements(elements);
                                    setNoResults(false);
                                    setState({ ...state, searchTerm: "" });
                                }}
                            />
                        </InputAdornment>
                    ),
                }}
                onChange={handleChange}
            /> */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "100%",
                    overflow: "hidden",
                }}
            >
                <TextField
                    name="search"
                    label="Search"
                    size="small"
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                        style: { fontSize: "16px", fontFamily: "Lucida Grande" },
                    }}
                    value={value}
                    InputProps={{
                        style: { font: "small-caption", fontFamily: "Lucida Grande" },
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Close
                                    fontSize="small"
                                    sx={{
                                        cursor: "pointer",
                                        padding: "4px",
                                    }}
                                    onClick={() => {
                                        setValue("");
                                        setSearchTerm("");
                                        setFilteredElements(elements);
                                        setNoResults(false);
                                        setState({ ...state, searchTerm: "" });
                                    }}
                                />
                            </InputAdornment>
                        ),
                    }}
                    onChange={handleChange}
                    sx={{
                        width: "100%",
                        maxWidth: "400px",
                        '@media (max-width: 600px)': {
                            maxWidth: "100%",
                        },
                    }}
                />
            </Box>
            {noResults && (
                <Alert severity="warning">
                    No results found with term: {value}
                </Alert>
            )}
        </>
    );
}