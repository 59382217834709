import { Delete } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import GenericTable from "../../edvenswa.emportal.common/pages/GenericTable";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import { AUTH_SECURE_USER_DETAILS_LS_LEY } from "../../edvenswa.emportal.auth/constants/Constants";
import AddHolidays from "./AddHolidays";
import { useLocation } from "react-router-dom";

export default function Holiday(props) {
  const { onSuccess } = props;
  const location = useLocation();
  const user = JSON.parse(
    sessionStorage.getItem(AUTH_SECURE_USER_DETAILS_LS_LEY)
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [filterHolidays, setFilterHolidays] = useState([]);
  const type = "Holidays";
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageSize] = useState(10);
  const totalPages = 0;
  const holidayTitle = "";
  const [tenantId, setTenantId] = useState(user.tenantId);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const displayAdd = true;

  useEffect(() => {
    setLoading(true);
    if (searchTerm.length === 0) {
      axiosInstance
        .get(`/holiday/holidaysLists?tenantId=${tenantId}`)
        .then((res) => {
          const currentYearHolidays = res?.data?.filter((holiday) => {
            const holidayDate = new Date(holiday?.date);
            return holidayDate.getFullYear() === selectedYear;
          });
          setHolidays(currentYearHolidays);
          setFilterHolidays(currentYearHolidays);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const searchedHolidays = holidays.filter((data) =>
        data["title"].toLowerCase().includes(searchTerm)
      );
      setFilterHolidays(searchedHolidays);
      setLoading(false);
    }
    // if (holidayTitle.length > 0) {
    //   axiosInstance.get(`/holiday/title?title=${holidayTitle}`).then((res) => {
    //     setSelectedHoliday(res.data);
    //   });
    // }
  }, [page, pageSize, searchTerm, holidayTitle, tenantId, selectedYear]);

  const header = [
    {
      id: "title",
      label: "title",
      type: "text",
    },
    { id: "date", label: "date", type: "date" },
  ];

  const CustomButtonComponent = (props) => {
    return (
      <>
        <Tooltip title="click to delete">
          <IconButton
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "#e74c3c",
              },
            }}
            onClick={() => {
              setSelectedRow(props.data.id);
              setIsDeleteDialogOpen(true);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
        <Dialog
          open={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete holiday? This action cannot be
              undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              sx={{ color: "white" }}
              variant="contained"
              onClick={() => setIsDeleteDialogOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              size="small"
              sx={{ color: "white" }}
              onClick={() => {
                setLoading(true);
                axiosInstance
                  .delete(`/holiday/delete?id=${selectedRow}`)
                  .then((res) => {
                    const currentYearHolidays = res?.data?.filter((holiday) => {
                      const holidayDate = new Date(holiday?.date);
                      return holidayDate.getFullYear() === selectedYear;
                    });
                    setHolidays(currentYearHolidays);
                    setFilterHolidays(currentYearHolidays);
                    setLoading(false);
                    onSuccess("Holiday deleted successfully");
                    setIsDeleteDialogOpen(false);
                  })
                  .catch((err) => {
                    console.log(err);
                    setLoading(false);
                  });
                setIsDeleteDialogOpen(false);
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const colDefs = [
    // { field: "logo", cellRenderer: CustomLogoComponent, type: "text",width:200 },
    { field: "title", headerName: "Title", type: "text", flex: 1 },
    {
      field: "date",
      label: "date",
      type: "date",
      flex: 1,
      filter: true,
      valueFormatter: (startDate) =>
        moment(startDate.value, "YYYY,MM,DD").format("DD-MMM-YYYY"),
    },
    {
      field: "Action",
      headerName: "Action",
      type: "text",
      cellRenderer: CustomButtonComponent,
      flex: 1,
    },
  ];
  useEffect(() => {
    if (location.state?.isDrawerOpen) {
      setIsDrawerOpen(true);
    }
  }, [location.state]);

  return (
    <GenericTable
      selectedYear={selectedYear}
      setSelectedYear={setSelectedYear}
      type={type}
      Companies={props.Companies}
      setCompanies={props.setCompanies}
      setHolidays={setHolidays}
      elements={holidays}
      colDefs={colDefs}
      filteredElements={filterHolidays}
      setFilteredElements={setFilterHolidays}
      fields={header}
      setSearchTerm={setSearchTerm}
      setTenantId={setTenantId}
      searchTerm={searchTerm}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
      snackbarOpen={snackbarOpen}
      setSnackbarOpen={setSnackbarOpen}
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      loading={loading}
      drawerContent={
        <AddHolidays
          selectedYear={selectedYear}
          tenantId={tenantId}
          companies={props.Companies}
          onError={props.onError}
          onSuccess={props.onSuccess}
          setIsDrawerOpen={setIsDrawerOpen}
          setFilteredElements={setFilterHolidays}
          setElements={setHolidays}
        />
      }
      displayAdd={displayAdd}
      displayHeader={displayAdd}
      // displayPagination={displayAdd}
    />
  );
}
