import { Box, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import React, { Suspense } from "react";
import { Routes } from "react-router-dom";
import "./App.css";
import { AUTH_ROUTES } from "./components/edvenswa.emportal.auth/route/Route";
import { CONSOLE_ROUTES } from "./components/edvenswa.emportal.console/route/Routes";
import { HOLIDAY_ROUTES } from "./components/edvenswa.emportal.holiday/route/Route";
import { HOME_ROUTES } from "./components/edvenswa.emportal.home/route/Route";
import { PROFILE_ROUTES } from "./components/edvenswa.emportal.profile/routes/Route";
import { PROJECT_ROUTES } from "./components/edvenswa.emportal.project/routes/Routes";
import { REPORT_ROUTES } from "./components/edvenswa.emportal.reports/routes/ReportRoutes";
import { TENANT_ROUTES } from "./components/edvenswa.emportal.tenant/routes/Routes";
import { TIMESHEET_ROUTES } from "./components/edvenswa.emportal.timesheet/routes/Routes";
import { APPRAISAL_ROUTES } from "./components/edvenswa.emportal.UserAppraisal/routes/Routes";
import { BIWEEKLY_ROUTES } from "./components/edvenswa.emportal.biweeklysprint/route/Route";

import GenericLoader from "./GenericLoader";
function App() {

  return (
    <Suspense fallback={
      <div>
        <Box sx={{ marginTop: '250px', display: 'flex', justifyContent: 'center', }}>
          {/* <CircularProgress
            variant="indeterminate"
            // disableShrink
            sx={{
              //   // color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
              //   // animationDuration: '550ms',
              position: 'absolute',
              //   // left: 0,
              //   [`& .${circularProgressClasses.circle}`]: {
              //     strokeLinecap: 'round',
              //   },
            }}
            size={40}
            thickness={4}
          /> */}
          <GenericLoader />
          <Typography sx={{ marginTop: '70px' }} variant='body2'>Loading may take a while...</Typography>
        </Box>
      </div>}>
      {/* <BrowserRouter> */}
      <Routes>
        {AUTH_ROUTES}
        {HOME_ROUTES}
        {PROJECT_ROUTES}
        {TIMESHEET_ROUTES}
        {TENANT_ROUTES}
        {HOLIDAY_ROUTES}
        {CONSOLE_ROUTES}
        {PROFILE_ROUTES}
        {REPORT_ROUTES}
        {/* {ANALAYTICS_ROUTES} */}
        {APPRAISAL_ROUTES}
        {/* {MissingRoute} */}
        {BIWEEKLY_ROUTES}
      </Routes>
      {/* </BrowserRouter> */}
    </Suspense>
  );
}

export default App;

// const Loader = () => {
//   return <>
//     <PrivateRoute>
//       <Layout component={LoadingPage} ></Layout>
//     </PrivateRoute>
//   </>
// }



