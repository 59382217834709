import { MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";

const YearDropdown = (props) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 30 }, (_, index) => currentYear - index); 
  const { selectedYear, setSelectedYear } = props;

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };

  return (
    <TextField
      size="small"
      select
      InputProps={{
        style: {
          font: "small-caption",
          fontFamily: "Lucida Grande",
        },
      }}
      value={selectedYear}
      onChange={handleChange}
      label="Year"
    >
      {years.map((year) => (
        <MenuItem
          style={{
            font: "small-caption",
            fontFamily: "Lucida Grande",
          }}
          key={year}
          value={year}
        >
          {year}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default YearDropdown;
