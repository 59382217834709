import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Rating,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  Tooltip as ChartTooltip,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
} from "chart.js";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import { OldUserAppraisal } from "./OldRatingsPage";
import { OldTableheader } from "./OldUserAppraisal";
import { TaskDialog } from "./TaskDialog";
import { Info } from "@mui/icons-material";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ChartTooltip,
  Title,
  Legend
);

const UserAppraisal = (props) => {
  const { user, pm, projects, isDisabled,onSuccess } = props;
  const role = user.roles[0];
  const [ratings, setRatings] = useState([]);
  const [commentforEachObjective, setCommentForEachObjective] = useState([]);
  const [appraisaldata, setAppraisalData] = useState({ comments: "" });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmittedForRange, setIsSubmittedForRange] = useState(false);
  const location = useLocation();
  const [menuanchorEl, setMenuAnchorEl] = useState(null);
  const [comments, setComments] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [severity, setSeverity] = useState("success", "error");
  const [message, setMessage] = useState("");
  const [submitloading, setSubmitLoading] = useState(false);
  const [pastRatings, setPastRatings] = useState({});
  const [projectManager, setProjectManager] = useState({});
  const navigate = useNavigate();
  const [isPreviousRatingPresent, setIsPreviousRatingsPresent] =
    useState(false);
  const [previousRatings, setPreviousRatings] = useState([]);
  const [teamRatings, setTeamRatings] = useState({});
  const [companyRatings, setCompanyRatings] = useState({});
  const [UKScores, setUKScores] = useState({});
  // const [startDate, setStartDate] = useState(location?.state ? location.state.startDate : moment(new Date()).format('DD') > 15 ? moment(new Date()).startOf('month').add(15, 'day').format('YYYY-MM-DD') : moment(new Date()).startOf('month').format('YYYY-MM-DD'));
  // const [endDate, setEndDate] = useState(location?.state ? location.state.endDate : moment(new Date()).format('DD') > 15 ? moment(new Date()).endOf('month').format('YYYY-MM-DD') : moment(new Date()).startOf('month').add(14, 'days').format('YYYY-MM-DD'));
  const astId = props?.astId ?? location?.state?.astId;
  const project = props?.project ?? location?.state?.project;
  const [startDate, setStartDate] = useState(
    location?.state?.startDate ?? props?.startDate
  );
  const [endDate, setEndDate] = useState(
    location?.state?.endDate ?? props?.endDate
  );
  const [projectManagers, setProjectManagers] = useState([]);
  const [selectedPM, setSelectedPM] = useState({});
  const [open, setOpen] = useState(false);
  const [validation,setValidation] = useState("");

  const getCurrentDateTime = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(now.getDate()).padStart(2, "0");

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const [startDateAndTime, setStartDateAndTime] = useState(
    getCurrentDateTime()
  );
  //const tstProjects = project.includes(",") ? project.split(",") : [project];

  useEffect(() => {
    axiosInstance
      .get(`/user/company/ratings?endDate=${endDate}`)
      .then((res) => setCompanyRatings(res?.data))
      .catch((err) => console.log(err));
    setIsPreviousRatingsPresent(false);
    setPreviousRatings([]);
    if (astId) {
      axiosInstance
        .get(`/user/get/project/managers?astId=${astId}`)
        .then((res) => {
          if (role === "Project Manager" && user?.associateId === astId) {
            const filteredProjectManagers = res?.data.filter(
              (p) => p.associateId !== astId
            );
            setSelectedPM(filteredProjectManagers[0]);
            setProjectManagers(filteredProjectManagers);
          } else {
            if (role === "Project Manager" && user) {
              const pm = res?.data.filter(
                (p) => p.associateId === user?.associateId
              );
              setSelectedPM(pm[0]);
            } else {
              setSelectedPM(res?.data[0]);
            }
            setProjectManagers(res?.data);
          }

          // Update the dropdown options
          //setProjectManagers(filteredProjectManagers);
        })
        /* if (role === "Project Manager" && user) {
            const pm = res?.data.filter(
              (p) => p.associateId === user?.associateId
            );
            setSelectedPM(pm[0]);
          } else {
            setSelectedPM(res?.data[0]);
          }
          setProjectManagers(res?.data);
        })*/
        .catch((err) => console.error(err.response?.data || err.message));

      axiosInstance
        .put(`/user/get/past/ratings?endDate=${endDate}`, [astId])
        .then((res) => setPastRatings(res?.data))
        .catch((err) => console.log(err));
    }

    if (astId) {
      axiosInstance
        .put(`/user/ast/ratings?endDate=${endDate}`, [astId])
        .then((res) => setUKScores(res?.data))
        .catch((err) => console.log(err));
    }

    if (project) {
      axiosInstance
        .get(`/user/team/ratings?endDate=${endDate}&project=${project}`)
        .then((res) => setTeamRatings(res?.data))
        .catch((err) => console.log(err));
    }

    //const projects = project.split(",");

    // axiosInstance
    //   .put(`/user/getAssociateRatings?pm=${selectedPM?.associateId}`, {
    //     startDate: startDate,
    //     endDate: endDate,
    //     user: { associateId: astId },
    //     project: { title: projects?.[0] },
    //   })
    //   .then((res) => {
    //     if (
    //       res.data?.[0].startDate === startDate &&
    //       res.data[0]?.endDate === endDate
    //     ) {
    //       setAppraisalData(res.data?.[0] ?? {});
    //     } else {
    //       let Obj = res.data[0];
    //       Obj["projectManagerSubmission"] = false;
    //       Obj["userSideSubmission"] = false;
    //       setAppraisalData(Obj);
    //     }
    //     if (res.data?.[0]?.ratingsObj !== null) {
    //       setComments(res.data?.[0]?.comments);
    //       setRatings(JSON.parse(res.data?.[0]?.ratingsObj));
    //       setCommentForEachObjective(JSON.parse(res.data?.[0]?.commentObj));
    //       setIsSubmittedForRange(true);
    //     } else {
    //       setIsSubmittedForRange(false);
    //     }
    //     if (res?.data?.[1] && res.data?.[1]?.ratingsObj !== null) {
    //       setPreviousRatings(JSON.parse(res.data?.[1]?.ratingsObj));
    //       setIsPreviousRatingsPresent(true);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [startDate, endDate, submitted]);

  useEffect(() => {
    if (Object.keys(selectedPM).length > 0) {
      console.log({
        url: `/user/getAssociateRatings?pm=${selectedPM?.associateId}`,
        body: {
          projectManagerId: selectedPM?.associateId,
          startDate,
          endDate,
          user: { associateId: astId },
          project: { title: selectedPM?.projects },
        },
      });

      axiosInstance
        .put(`/user/getAssociateRatings?pm=${selectedPM?.associateId}`, {
          projectManagerId:
            new Date(startDate).getFullYear() < 2025
              ? null
              : selectedPM?.associateId,
          startDate: startDate,
          endDate: endDate,
          user: { associateId: astId },
          project: { title: selectedPM?.projects },
        })
        .then((res) => {
          if (
            res.data?.[0].startDate === startDate &&
            res.data[0]?.endDate === endDate
          ) {
            setAppraisalData(res.data?.[0] ?? {});
          } else {
            let Obj = res.data[0];
            Obj["projectManagerSubmission"] = false;
            Obj["userSideSubmission"] = false;
            setAppraisalData(Obj);
            setRatings([]);
            setCommentForEachObjective([]);
            setComments("");
          }
          if (res.data?.[0]?.ratingsObj.length > 0) {
            setComments(res.data?.[0]?.comments);
            // setRatings(res?.data?.[0]?.ratingsList ?? []);
            setRatings(JSON.parse(res.data?.[0]?.ratingsObj));
            setCommentForEachObjective(JSON.parse(res.data?.[0]?.commentObj));
            setIsSubmittedForRange(true);
          } else {
            setRatings([]);
            setCommentForEachObjective([]);
            setComments("");
            setIsSubmittedForRange(false);
          }
          if (res?.data?.[1] && res.data?.[1]?.ratingsObj !== null) {
            setPreviousRatings(JSON.parse(res.data?.[1]?.ratingsObj));
            setIsPreviousRatingsPresent(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedPM]);

  const calculateAverageRatings = (teamRatings) => {
    const dateRatingsMap = {};

    Object.values(teamRatings).forEach(({ dates, ukScores }) => {
      dates.forEach((date, index) => {
        const range = `${moment(date.startDate).format("MMM D")} - ${moment(
          date.endDate
        ).format("MMM D")}`;
        if (!dateRatingsMap[range]) {
          dateRatingsMap[range] = [];
        }
        dateRatingsMap[range].push(ukScores[index]);
      });
    });

    const averages = Object.keys(dateRatingsMap).map((range) => {
      const ratings = dateRatingsMap[range];
      const average =
        ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length;
      return { range, average: Math.trunc(average) };
    });

    return averages;
  };
  const averageRatings = calculateAverageRatings(teamRatings);
  const averageCompanyRatings = calculateAverageRatings(companyRatings);

  const labels = UKScores?.[astId]?.dates?.map((date) => {
    const startDate = moment(date.startDate).format("MMM DD");
    const month = moment(date.startDate).format("MMM");
    const endDate = moment(date.endDate).format("DD");
    if (!`${startDate} - ${endDate}`.includes("16")) {
      return month;
    }
    return "";
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          font: {
            size: 10, // Adjust font size here (e.g., 10px)
          },
          boxWidth: 14, // Adjust the size of the color box (square)
          padding: 10, // Space around the legend items
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const datasetIndex = tooltipItem.datasetIndex;
            const dataIndex = tooltipItem.dataIndex;
            let startDate;
            let endDate;
            const dateRange = UKScores?.[astId]?.dates[dataIndex];
            startDate = moment(dateRange.startDate).format("MMM DD");
            endDate = moment(dateRange.endDate).format("DD");
            if (datasetIndex === 0) {
              const astUKScore = UKScores?.[astId]?.ukScores[dataIndex];

              if (dateRange && astUKScore !== undefined) {
                return `Range: ${startDate} - ${endDate}, My UK Score: ${astUKScore}`;
              }
            } else if (datasetIndex === 1) {
              const teamUKScore = averageRatings[dataIndex]?.average;

              if (teamUKScore !== undefined) {
                return `Range: ${startDate} - ${endDate}, Team UK Score: ${teamUKScore}`;
              }
            } else {
              const companyUKScore = averageCompanyRatings[dataIndex]?.average;

              if (companyUKScore !== undefined) {
                return `Range: ${startDate} - ${endDate}, Company UK Score: ${companyUKScore}`;
              }
            }
            return "";
          },
        },
        size: "100px",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date Range",
        },
      },
      y: {
        title: {
          display: true,
          text: "UK Score",
        },
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const chartData = {
    labels,
    datasets: [
      {
        label: "My UK Score",
        data: UKScores?.[astId]?.ukScores,
        borderColor: "purple",
        backgroundColor: "purple",
        borderWidth: 2,
        tension: 0.4,
        pointRadius: UKScores?.[astId]?.dates.map((date) =>
          date.startDate === startDate || date.endDate === startDate ? 8 : 5
        ),
        pointBackgroundColor: "purple",
      },
      {
        label: "Team UK Score",
        data: averageRatings.map((data) => data.average),
        borderColor: "orange",
        backgroundColor: "orange",
        borderWidth: 2,
        tension: 0.4,
        pointRadius: UKScores?.[astId]?.dates.map((date) =>
          date.startDate === startDate || date.endDate === startDate ? 8 : 5
        ),
        pointBackgroundColor: "orange",
      },
      {
        label: "Company UK Score",
        data: averageCompanyRatings.map((data) => data.average),
        borderColor: "teal",
        backgroundColor: "teal",
        borderWidth: 2,
        tension: 0.4,
        pointRadius: UKScores?.[astId]?.dates.map((date) =>
          date.startDate === startDate || date.endDate === startDate ? 8 : 5
        ),
        pointBackgroundColor: "teal",
      },
    ],
  };
  const oldheader = [
    "S.no",
    "Description",
    "Matrix & Target",
    "Rating(1-5)Low to High",
  ];
  const header = [
    "S.no",
    "PERF Category",
    "PERF Parameters",
    "Parameter Weightage",
  ];
  const isNewFormDate = moment(endDate).isAfter(moment(new Date("2024-12-31")));
  const UserAppraisal = isNewFormDate
    ? [
        {
          serialNo: 1,
          Objective: "Capability",
          subObjective: [
            {
              desc: "Capability",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Execution Efficiency
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Timeliness, resource optimization, utilization of
                          tools/ technology for better outcomes, adaptability,
                          and communication clarity, consistency in updates,
                          presentation, and quality of documentation.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 30,
            },
            {
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Quality of Output
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Adherence to specifications, design standards,
                          accuracy, and completeness of deliverables.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 30,
            },
            {
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Accountability and Ownership
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Taking responsibility, proactive decision-making, and
                          ensuring task completion.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 40,
            },
          ],
          rating: [
            { sno: 1, ratingValue: 1 },
            { sno: 2, ratingValue: 3 },
          ],
        },
        {
          serialNo: 2,
          Objective: "Creativity",
          subObjective: [
            {
              desc: "Creativity",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Originality
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Measures the uniqueness and innovation in ideas,
                          solutions, or approaches.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 20,
            },
            {
              desc: "Creativity",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Alignment
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Evaluates how well actions and outcomes are in sync
                          with the goals of the project, client requirements,
                          organizational vision, and potential for meaningful
                          change.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 60,
            },
            {
              desc: "Creativity",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Complexity and Effort
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Tracks the level of difficulty and resources required
                          to achieve a result.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 20,
            },
          ],
          rating: [1, 2, 3, 4, 5, 6],
        },
        {
          serialNo: 3,
          Objective: "Collaboration",
          subObjective: [
            {
              desc: "Collaboration",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Team Contribution
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Measures active involvement and willingness to assist
                          team members in achieving shared goals.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 40,
            },
            {
              desc: "Collaboration",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Adaptability & Flexibility
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Evaluates readiness to adjust to changing team
                          dynamics, roles, or priorities.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 20,
            },
            {
              desc: "Collaboration",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Relationship Building
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Tracks the ability to foster trust, mutual respect,
                          open communication within the team, and conflict
                          resolution.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 40,
            },
          ],
          rating: [{ sno: 1, ratingValue: 4 }],
        },

        {
          serialNo: 4,
          Objective: "Compliance",
          subObjective: [
            {
              desc: "Compliance",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Awareness
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Evaluates understanding of organizational policies and
                          procedures and the ability to promote compliance among
                          peers.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: "gray" },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 30,
            },
            {
              desc: "Compliance",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Adherence
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Measures consistency in following established rules,
                          processes, timelines and the ability to resolve
                          compliance-related issues by identifying and
                          addressing them promptly.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: "gray" },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 30,
            },
            {
              desc: "Compliance",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Accuracy and Integrity of Records
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Tracks the precision and honesty in maintaining
                          compliance-related documentation and records.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: "gray" },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 40,
            },
          ],
          rating: [{ sno: 1, ratingValue: 3 }],
        },
        {
          serialNo: 5,
          Objective: "Customer",
          subObjective: [
            {
              desc: "Customer",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Convenience
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Deep commitment to understanding customer requirements
                          and delivering with ease and efficiency to exceed
                          their expectations.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: "gray" },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 30,
            },
            {
              desc: "Customer",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Value Creation
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Emphasizes providing increasingly customer-friendly
                          options and solutions that enhance the customer
                          experience, ensuring they continue to stay with us
                          while contributing to business growth.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: "gray" },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 20,
            },
            {
              desc: "Customer",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Cost Optimization
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Promotes operational efficiency and cost management to
                          benefit both the company and its customers.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: "gray" },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 10,
            },
            {
              desc: "Customer",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Brand Promotion
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Aims to enhance the company’s market perception and
                          reputation through consistent and positive customer
                          experiences.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: "gray" },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                </>
              ),
              weightage: 40,
            },
          ],
          rating: [{ sno: 1, ratingValue: 3 }],
        },
      ]
    : OldUserAppraisal;

  // let ratingObj = {}
  // const handleChange = (rowIndex, index, rating) => {
  //     ratingObj = { ...ratingObj, rowIndex: rowIndex, subIndex: index, rating: rating }

  //     setRatings((arr) => {
  //         const isAlreadyExisted = arr.find((rObj) => rObj.rowIndex === ratingObj.rowIndex && rObj.subIndex === ratingObj.subIndex)
  //         if (isAlreadyExisted) {
  //             arr.pop(isAlreadyExisted);
  //             arr.push(ratingObj);
  //         }
  //         else {
  //             arr.push(ratingObj);
  //         }
  //         return arr;
  //     })

  // }

  const handleDownloadPDF = () => {
    const pdfWidth = 210;
    const pdfHeight = 250;
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: [pdfWidth, pdfHeight],
    });

    const logoUrl1 =
      "https://tse3.mm.bing.net/th?id=OIP.xrnfPsu-nIEVf0HzuyWt3AAAAA&pid=Api&P=0&h=180";
    const logoUrl2 =
      "https://media-hosting.imagekit.io//345db9943dbb45b1/emportal-me%201%20(1).png?Expires=1734776498&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=bjE--syrSN-EUHcJ0ul6NP0lVCNiAePshUfvsW2QXdgV4iUjgcLO7CEEJiHCGN1DX4-fiz2~k4tTl-LXw2Iqc30dbkED~de1n4FD8zQ1kWk6l8tZCFNIjQtJ9y6~0ntCjMo5t0zEqVLiRmeBeBOy8kZDJB40JyTnh8iGCOPm-~xVStSpApjmkpyKRuYlaN8qtKHBIlC6zdcFHl0er4nQzG31RGeZA5DhmZdygWxYOrl7WsbuIWpGb~ns4ATBlq~p9z7z3xGZ7iMLjM3evz~qkpIvlScRdi~mqgcjTi8l3iImy8X~e3LUGLL61twZ0X6MbmUt4svCY-LfOdoNVNd0oA__";

    const pageWidth = pdf.internal.pageSize.width;
    const margin = 10;

    const imageWidth1 = 30;
    const imageHeight1 = 25;
    const logoY = 10;

    pdf.addImage(logoUrl1, "PNG", margin, logoY, imageWidth1, imageHeight1);

    const originalWidth = 345;
    const originalHeight = 94;
    const maxLogoWidth = 30;
    const scale = maxLogoWidth / originalWidth;

    const imageWidth2 = originalWidth * scale;
    const imageHeight2 = originalHeight * scale;

    const logoX2 = pageWidth - imageWidth2 - margin;

    pdf.addImage(logoUrl2, "PNG", logoX2, logoY, imageWidth2, imageHeight2);

    const titleY = logoY + Math.max(imageHeight1, imageHeight2) + 10;
    pdf.setFont("Times New Roman, Times, serif");
    pdf.setFontSize(17);
    const title = `Performance Evaluation, Rating & Feedback of ${location.state.fullname}`;
    pdf.text(title, pageWidth / 2, titleY, { align: "center" });

    const chartCanvas = document.querySelector("canvas");
    const chartImage = chartCanvas.toDataURL("image/png");

    const chartWidth = 80;
    const chartHeight = 50;
    const chartX = pageWidth - chartWidth - margin;
    const chartY = titleY + 10;

    pdf.addImage(chartImage, "PNG", chartX, chartY, chartWidth, chartHeight);

    const detailsY = chartY;
    pdf.setFontSize(12);

    const lineSpacing = 8;
    const details = [
      `Associate ID : ${appraisaldata.associateId}`,
      `Full Name : ${appraisaldata.fullname}`,
      `Designation : ${appraisaldata.designation}`,
      `Project Manager : ${appraisaldata.projectManager.fullname}`,
      `Uk Rating : ${(totalRatingSum / 11).toFixed(2)}`,
      `Feedback : ${comments || "No comments available"}`,
    ];

    const maxWidth = 130;

    details.forEach((detail, index) => {
      const wrappedText = pdf.splitTextToSize(detail, maxWidth);
      pdf.text(wrappedText, margin, detailsY + index * lineSpacing);
    });

    const tableStartY = Math.max(
      chartY + chartHeight + 10,
      detailsY + details.length * lineSpacing + 10
    );

    const splitData = UserAppraisal.flatMap((data, rowIndex) => {
      const descriptions = data.subObjective
        .map((sub) => sub.desc || "")
        .join("\n")
        .split("\n");
      const matrixTargets = data.subObjective
        .map((sub) => sub.matrixTarget || "")
        .join("\n")
        .split("\n");
      const ratingsDemo = data.subObjective
        .map(
          (sub, subIndex) =>
            ratings.find(
              (r) => r.rowIndex === rowIndex && r.subIndex === subIndex
            )?.rating || 0
        )
        .join("\n")
        .split("\n");

      const maxRows = Math.max(
        descriptions.length,
        matrixTargets.length,
        ratingsDemo.length
      );
      return Array.from({ length: maxRows }, (_, i) => ({
        "s.no": data.serialNo,
        Description: descriptions[i] || "",
        "Matrix & Target": matrixTargets[i] || "",
        "Rating (1-5)Low to High": ratingsDemo[i] || "",
      }));
    });

    const header = [
      "s.no",
      "Description",
      "Matrix & Target",
      "Rating (1-5)Low to High",
    ];

    const userData = splitData.map((val) =>
      header.map((header) => val[header])
    );

    pdf.autoTable({
      head: [header],
      body: userData,
      margin: { left: 8, right: 8 },
      startY: tableStartY,
      rowPageBreak: "avoid",
      headStyles: {
        fillColor: "#008080",
        textColor: "#ffffff",
        fontStyle: "bold",
        fontSize: 10,
      },
      bodyStyles: {
        fontSize: 11,
      },
      didDrawPage: function (data) {
        const now = new Date();
        const timestamp = `${now.getFullYear()}-${(now.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")} ${now
          .getHours()
          .toString()
          .padStart(2, "0")}:${now.getMinutes().toString().padStart(2, "0")}`;
        pdf.setFontSize(10);
        pdf.text(
          `Generated on: ${timestamp} | emportal.me`,
          pageWidth / 2,
          pdf.internal.pageSize.height - 5,
          { align: "center" }
        );
      },
    });

    pdf.save(`${location.state.fullname}.pdf`);
  };

  const handleDownloadExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);

    const splitData = UserAppraisal.flatMap((data, rowIndex) => {
      const descriptions = data.subObjective
        .map((sub) => sub.desc || "")
        .join("\n")
        .split("\n");
      const matrixTargets = data.subObjective
        .map((sub) => sub.matrixTarget || "")
        .join("\n")
        .split("\n");
      const ratingsDemo = data.subObjective
        .map(
          (sub, subIndex) =>
            ratings.find(
              (r) => r.rowIndex === rowIndex && r.subIndex === subIndex
            )?.rating || 0
        )
        .join("\n")
        .split("\n");

      const maxRows = Math.max(
        descriptions.length,
        matrixTargets.length,
        ratingsDemo.length
      );
      return Array.from({ length: maxRows }, (_, i) => ({
        "s.no": data.serialNo,
        Description: descriptions[i] || "",
        "Matrix & Target": matrixTargets[i] || "",
        "Rating (1-5)Low to High": ratingsDemo[i] || "",
      }));
    });

    const header = [
      "s.no",
      // "Objective",
      "Description",
      "Matrix & Target",
      "Rating (1-5)Low to High",
    ];

    XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
    splitData.forEach((data, index) => {
      const rowData = header.map((header) => data[header]);
      XLSX.utils.sheet_add_aoa(ws, [rowData], { origin: `A${index + 2}` });
    });

    const columnWidths = header.map((col) => ({ wch: col.length + 10 }));
    ws["!cols"] = columnWidths;
    XLSX.utils.book_append_sheet(wb, ws, "Appraisal Report");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelBlob, `${location.state.fullname}.xlsx`);
  };

  // added by anil start
  const rating_titles = [
    {
      title: "Capability",
      perf: [
        "Execution Efficiency",
        "Quality of Output",
        "Accountability and Ownership",
      ],
    },
    {
      title: "Creativity",
      perf: ["Originality", "Alignment", "Complexity and Effort"],
    },
    {
      title: "Collaboration",
      subObjective: [
        "Team Contribution",
        "Adaptability & Flexibility",
        "Relationship Building",
      ],
    },
    {
      title: "Compliance",
      perf: ["Awareness", "Adherence", "Accuracy and Integrity of Records"],
    },
    {
      title: "Customer",
      perf: [
        "Compliance",
        "Value Creation",
        "Cost Optimization",
        "Brand Promotion",
      ],
    },
  ];
  /* const rateStartTime = useMemo(() => {
        const now = new Date();
        return now.toLocaleString();
      }, []); */
  const rateStartTime = getCurrentDateTime();

  const submitRatonClick = (data) => {
    /*  axiosInstance.post("/user/submitRatings123", data).then(res => {
        console.log("Ratings Submitted Successfully!");
        }).catch(err => {
        console.log(err);
        }) */
    let LOG_URL = process.env.REACT_APP_EMPORTAL_SERVICE_LOG_URL;
    fetch(LOG_URL + "/submitRatings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).catch((err) => {
      console.log(err);
    });
  };

  // aaded by anil end

  const handleChange = (rowIndex, subIndex, newValue, weightage) => {
    // aaded by anil start
    let em = appraisaldata?.firstname + " " + appraisaldata?.lastname;
    let pm = appraisaldata?.projectManager?.fullname;
    let perf_title = rating_titles[rowIndex].title;
    let perf_subtitle = rating_titles[rowIndex]?.perf?.[subIndex] || "";
    let project =
      astId === user?.associateId
        ? null
        : {
            id:
              role === "Reporting Manager"
                ? props.projects?.id
                : role === "User"
                ? 26
                : props.projects.find(
                    (data) => data.title === location.state.project
                  ).id,
          };
    let rateEndTime = getCurrentDateTime();

    let RateData = {
      project_id: project?.id,
      project_name: appraisaldata.project,
      startDate: startDate,
      endDate: endDate,
      perf_title: perf_title,
      perf_subtitle: perf_subtitle,
      rowIndex: rowIndex,
      subindex: subIndex,
      newValue: newValue,
      weightage: weightage,
      associateId: appraisaldata.associateId,
      associate_name: appraisaldata?.firstname + " " + appraisaldata?.lastname,
      rated_by: user.associateId,
      rated_by_name: user.firstname + " " + user.lastname,
      rateStartTime: rateStartTime,
      rateEndTime: rateEndTime,
    };
    //submitRatonClick(RateData);

    // aaded by anil end

    setRatings((prevRatings) => {
      // Update existing ratings or add new one
      const updatedRatings = prevRatings.map((r) =>
        r.rowIndex === rowIndex && r.subIndex === subIndex
          ? {
              ...r,
              rating: newValue,
              weightagedRating: (newValue * weightage) / 100,
              rateStartTime: rateStartTime,
              rateEndTime: rateEndTime,
            } // Update existing rating
          : r
      );

      // If no rating was updated, add a new entry
      if (
        !updatedRatings.some(
          (r) => r.rowIndex === rowIndex && r.subIndex === subIndex
        )
      ) {
        updatedRatings.push({
          rowIndex,
          subIndex,
          rating: newValue,
          weightagedRating: (newValue * weightage) / 100,
          rateStartTime: rateStartTime,
          rateEndTime: rateEndTime,
        });
      }
      return updatedRatings;
    });
  };

  const handleCommentChange = (rowIndex, subIndex, newValue) => {
    let comtEndTime = getCurrentDateTime();
    const currentRating = ratings.find(
      (r) => r.rowIndex === rowIndex && r.subIndex === subIndex
    )?.rating || 0;  
    const validationError = validateComment(currentRating, newValue);

    // Set validation error for specific rowIndex and subIndex
  if (validationError) {
    setValidation((prevValidation) => ({
      ...prevValidation,
      [`${rowIndex}-${subIndex}`]: validationError,
    }));
  } else {
    setValidation((prevValidation) => {
      const updatedValidation = { ...prevValidation };
      delete updatedValidation[`${rowIndex}-${subIndex}`]; // Remove the error if no validation
      return updatedValidation;
    });
  }
    
    setRatings((prevRating) => {
      const updatedRatings = prevRating.map((r) =>
        r.rowIndex === rowIndex && r.subIndex === subIndex
          ? {
              ...r,
              comment: newValue,
              comtStartTime: rateStartTime,
              ratecmtEndTime: comtEndTime,
            } // Update existing rating
          : r
      );
      // If no rating was updated, add a new entry
      if (
        !updatedRatings.some(
          (r) =>
            r.rowIndex === rowIndex &&
            r.subIndex === subIndex &&
            r.comment !== null
        )
      ) {
        updatedRatings.push({
          rowIndex,
          subIndex,
          rating: 0,
          comment: newValue,
          comtStartTime: rateStartTime,
          ratecmtEndTime: comtEndTime,
        });
      }
      return updatedRatings;
    });

    setCommentForEachObjective((prevComments) => {
      // Update existing ratings or add new one
      const updatedComments = prevComments.map((r) =>
        r.rowIndex === rowIndex && r.subIndex === subIndex
          ? {
              ...r,
              comment: newValue,
              comtStartTime: rateStartTime,
              ratecmtEndTime: comtEndTime,
            } // Update existing rating
          : r
      );

      // If no rating was updated, add a new entry
      if (
        !updatedComments.some(
          (r) => r.rowIndex === rowIndex && r.subIndex === subIndex
        )
      ) 
      {
        updatedComments.push({
          rowIndex,
          subIndex,
          comment: newValue,
          comtStartTime: rateStartTime,
          ratecmtEndTime: comtEndTime,
        });
      }
      return updatedComments;
    });
  };

  const validateComment = (rating, comment) => {
    const wordCount = comment.trim().split(/\s+/).length; // Count words
    if ((rating === 1 || rating === 5 || rating === 1.5 || rating === 4.5) && wordCount < 20) {      
      return `Rating ${rating} requires at least 20 words.`;
    }
    if ((rating === 2 || rating === 4 || rating === 2.5 || rating ===3.5) && wordCount < 10) {
      return `Rating ${rating} requires at least 10 words.`;
    }
    return null; // No validation error
  };

  const handleDownloadMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  let totalRatingSum = 0;
  ratings.map((r) => (totalRatingSum += r.weightagedRating));

  const handlePrevDateChange = (e, type) => {
    setRatings([]);
    setComments("");
    setIsSubmittedForRange(false);
    setSubmitted(false);
    if (type === "icon") {
      const newStartDate =
        moment(startDate).format("DD").toString() === "01"
          ? moment(startDate)
              .subtract(1, "month")
              .startOf("month")
              .add(15, "days")
              .format("YYYY-MM-DD")
          : moment(startDate).startOf("month").format("YYYY-MM-DD");
      const newEndDate =
        moment(startDate).format("DD").toString() === "01"
          ? moment(startDate)
              .subtract(1, "month")
              .endOf("month")
              .format("YYYY-MM-DD")
          : moment(startDate)
              .startOf("month")
              .add(14, "day")
              .format("YYYY-MM-DD");
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else {
      const value = e.target.value;
      setStartDate(value);
    }
  };

  const handleNextDateChange = (e, type) => {
    setRatings([]);
    setComments("");
    setIsSubmittedForRange(false);
    setSubmitted(false);
    if (type === "icon") {
      const newStartDate =
        moment(startDate).format("DD").toString() === "01"
          ? moment(startDate).add(15, "days").format("YYYY-MM-DD")
          : moment(startDate)
              .add(1, "month")
              .startOf("month")
              .format("YYYY-MM-DD");
      const newEndDate =
        moment(startDate).format("DD").toString() === "01"
          ? moment(startDate).endOf("month").format("YYYY-MM-DD")
          : moment(startDate)
              .add(1, "month")
              .startOf("month")
              .add(14, "days")
              .format("YYYY-MM-DD");
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else {
      const value = e.target.value;
      setEndDate(value);
    }
  };

  const renderDateFields = () => {
    return (
      <Box sx={{ display: "flex" }}>
        <Tooltip title="click to go to previous month">
          {location.state.type === "User" ? (
            <></>
          ) : (
            <IconButton onClick={(e) => handlePrevDateChange(e, "icon")}>
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
          )}
        </Tooltip>
        <TextField
          type="date"
          value={startDate}
          label="Start Date"
          size="small"
          error={
            startDate && new Date(startDate) > new Date(endDate)
              ? "Start date cannot be after end date"
              : ""
          }
          helperText={
            startDate && new Date(startDate) > new Date(endDate)
              ? "Start date cannot be after end date"
              : ""
          }
          InputLabelProps={{
            style: { font: "small-caption", fontFamily: "Lucida Grande" },
            shrink: true,
          }}
          InputProps={{
            style: { font: "small-caption", fontFamily: "Lucida Grande" },
          }}
          disabled={true}
          onChange={handlePrevDateChange}
        />
        &nbsp; &nbsp;
        <TextField
          type="date"
          value={endDate}
          label="End Date"
          size="small"
          error={
            startDate && new Date(endDate) < new Date(startDate)
              ? "End date cannot be before start date"
              : ""
          }
          helperText={
            startDate && new Date(endDate) < new Date(startDate)
              ? "End date cannot be before start date"
              : ""
          }
          InputLabelProps={{
            style: { font: "small-caption", fontFamily: "Lucida Grande" },
            shrink: true,
          }}
          InputProps={{
            style: { font: "small-caption", fontFamily: "Lucida Grande" },
          }}
          disabled={true}
          onChange={handleNextDateChange}
        />
        <Tooltip title="click to go to next month">
          {location.state.type === "User" ? (
            <></>
          ) : (
            <IconButton
              onClick={(e) => handleNextDateChange(e, "icon")}
              disabled={
                new Date(endDate) >=
                new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
              }
            >
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          )}
        </Tooltip>
      </Box>
    );
  };

  const handleClick = () => {
    setIsDialogOpen(false);

    const data = {
      startDateAndTime: startDateAndTime,
      endDateAndTime: getCurrentDateTime(),
      ratings: JSON.stringify(ratings),
      comments: comments,
      user: { associateId: astId },
      project:
        astId === user?.associateId
          ? {
              projectManager: {
                associateId: selectedPM?.associateId,
              },
            }
          : {
              id:
                role === "Reporting Manager"
                  ? props.projects?.id
                  : role === "User"
                  ? 26
                  : props.projects.find(
                      (data) => data.title === location.state.project
                    ).id,
              projectManager: {
                associateId: selectedPM?.associateId,
              },
            },
      startDate: startDate,
      endDate: endDate,
      ratingAvg: Math.floor(totalRatingSum * (20 / 100) * 100) / 100,
      commentObj: JSON.stringify(commentforEachObjective),
    };

    setSubmitLoading(true);
    if (ratings.length !== 0) {
      axiosInstance
        .post("/user/submitRatings", data)
        .then((res) => {
          setSubmitted(true);
          setIsSubmittedForRange(true);
          setSeverity("success");
          setMessage("Ratings Submitted Successfully!");
          setSnackbarOpen(true);
          setSubmitLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSubmitLoading(false);
        });
    } else {
      setSeverity("error");
      setMessage("Provide atleast 1 rating. Please try again.");
      setSnackbarOpen(true);
      setSubmitLoading(false);
    }
  };

  const validateRatings = () => {
    for (let rowIndex = 0; rowIndex < UserAppraisal.length; rowIndex++) {
      const subObjectives = UserAppraisal[rowIndex].subObjective;

      for (let subIndex = 0; subIndex < subObjectives.length; subIndex++) {
        const rating = ratings.find(
          (r) => r.rowIndex === rowIndex && r.subIndex === subIndex
        )?.rating;

        if (!rating || rating <= 0) {
          setSeverity("error");
          setMessage(" Please provide Ratings for all the fields.");
          setSnackbarOpen(true);
          return false;
        }
      }
    }

    return true;
  };

  const selfratingCheckingFunction = () => {
    return (
      role === "Admin" ||
      ((role === "User" ||
        (role === "Project Manager" && astId === user.associateId)) &&
        appraisaldata.userSideSubmission) ||
      (role === "Project Manager" &&
        astId !== user?.associateId &&
        appraisaldata.projectManagerSubmission)
    );
  };

  const getDifferenceChipStyle = (difference) => {
    if (difference > 0) {
      return { backgroundColor: "green", color: "white" }; // Positive difference
    } else if (difference > -1.0) {
      return { backgroundColor: "lightcoral", color: "white" }; // Negative but > -1.0
    } else {
      return { backgroundColor: "red", color: "white" }; // Difference <= -1.0
    }
  };

  const getChipLabel = (difference) => {
    return Math.abs(difference).toFixed(1); // Always show positive value, rounded to 1 decimal place
  };
  const weightedRating = (value) => {
    let sum = 0;
    ratings.forEach((data) => {
      if (
        data?.rowIndex === value &&
        typeof data?.weightagedRating === "number"
      ) {
        sum += data.weightagedRating;
      }
    });
    return sum === 0 ? 0 : Math.floor(sum * 100) / 100;
  };

  const NewTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              border: "1px solid",
              padding: "4px",
              backgroundColor: "#dde2eb",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lucida Grande, sans-serif",
                fontWeight: "bold",
              }}
            >
              S.no
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid",
              padding: "4px",
              backgroundColor: "#dde2eb",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lucida Grande, sans-serif",
                fontWeight: "bold",
              }}
            >
              PERF Category
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid",
              padding: "4px",
              backgroundColor: "#dde2eb",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lucida Grande, sans-serif",
                fontWeight: "bold",
              }}
            >
              PERF Parameters
            </Typography>
          </TableCell>

          <TableCell
            sx={{
              border: "1px solid",
              padding: "4px",
              backgroundColor: "#dde2eb",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lucida Grande, sans-serif",
                fontWeight: "bold",
              }}
            >
              PERF weightage
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid",
              padding: "4px",
              backgroundColor: "#dde2eb",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lucida Grande, sans-serif",
                fontWeight: "bold",
              }}
            >
              Rating(1-5)Low to High
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid",
              padding: "4px",
              backgroundColor: "#dde2eb",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lucida Grande, sans-serif",
                fontWeight: "bold",
              }}
            >
              Weighted Rating
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid",
              padding: "4px",
              backgroundColor: "#dde2eb",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lucida Grande, sans-serif",
                fontWeight: "bold",
              }}
            >
              Comments
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  return (
    <Card>
      <Grid container xs={12} mt={2} pl={2}>
        <Grid container item xs={6}>
          <Grid item xs={4}>
            <Typography variant="h6">PERF Rating Sheet</Typography>
          </Grid>
          {props?.showDateRange === false ? (
            <Grid item xs={4}></Grid>
          ) : (
            <>
              <Grid item xs={4}>
                {renderDateFields()}
              </Grid>
              <Grid item xs={4}>
                {role === "User" ? (
                  <></>
                ) : (
                  <IconButton
                    onClick={handleDownloadMenuClick}
                    // disabled={!isSubmittedForRange}  // Disable the button until submitted
                    // disabled={loading || Object.keys(errors).length !== 0}
                  >
                    <DownloadIcon />
                  </IconButton>
                )}
                <Menu
                  anchorEl={menuanchorEl}
                  open={Boolean(menuanchorEl)}
                  onClose={() => {
                    setMenuAnchorEl(null);
                  }}
                >
                  <MenuItem
                    style={{ font: "small-caption" }}
                    onClick={() => handleDownloadPDF()}
                  >
                    Download PDF
                  </MenuItem>
                  <MenuItem
                    style={{ font: "small-caption" }}
                    onClick={() => handleDownloadExcel()}
                  >
                    Download Excel
                  </MenuItem>
                </Menu>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Stack direction={"column"}>
              <Typography>
                {role !== "User" ? (
                  <div>
                    Associate Name:{" "}
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/home/users/details", {
                          state: {
                            user: { username: appraisaldata.username },
                            allusers: [],
                          },
                        });
                        // navigate("/home/ratings", { state: { projectManager: appraisaldata?.projectManager } })
                      }}
                    >
                      {appraisaldata?.fullname}
                    </Link>
                  </div>
                ) : (
                  `Associate Name: ${appraisaldata?.fullname}`
                )}
              </Typography>
              <Typography>
                Project:{" "}
                {role === "Reporting Manager"
                  ? selectedPM?.projects
                  : selectedPM?.projects}
              </Typography>
              <Typography>
                {role !== "User" ? (
                  <div>
                    Project Manager:{" "}
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/home/ratings", {
                          state: {
                            projectManager: selectedPM,
                          },
                        });
                      }}
                    >
                      {selectedPM?.fullName}
                    </Link>
                  </div>
                ) : (
                  `Project Manager: ${selectedPM?.fullName}`
                )}
              </Typography>
              <Typography>
                UK Score:{" "}
                {isSubmittedForRange
                  ? Math.floor(pastRatings?.[astId]?.finalRating * 180)
                  : Math.floor(totalRatingSum * (20 / 100) * 100) / 100}
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <Stack direction={"row"} spacing={2}>
            {((role === "Project Manager" &&
              appraisaldata?.fullname ===
                user.firstname + " " + user.lastname) ||
              role !== "Project Manager") && (
              <TextField
                size="small"
                select
                label="Project Manager"
                value={selectedPM}
                onChange={(e) => setSelectedPM(e.target.value)}
                InputProps={{
                  style: {
                    font: "small-caption",
                    fontFamily: "Lucida Grande",
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontSize: "12px", // You can adjust the font size of the label here
                  },
                }}
              >
                {projectManagers.map((pm) => (
                  <MenuItem
                    style={{
                      font: "small-caption",
                      fontFamily: "Lucida Grande",
                    }}
                    key={pm}
                    value={pm}
                  >
                    {pm?.fullName}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              size="small"
              sx={{
                padding: "2px 6px",
                fontSize: "12px",
                minWidth: "auto",
                height: "30px",
              }}
            >
              View Tasks
            </Button>
            {open && (
              <TaskDialog
                projects={selectedPM?.projects}
                open={open}
                setOpen={setOpen}
                startDate={startDate}
                endDate={endDate}
                astId={astId}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Line data={chartData} options={options} />
        </Grid>
      </Grid>
      <CardContent>
        <TableContainer id="content-to-print">
          <Table>
            {isNewFormDate ? NewTableHeader() : OldTableheader()}
            <TableBody>
              {UserAppraisal.map((data, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  {data.subObjective.map((sub, subIndex) => (
                    <TableRow key={subIndex}>
                      {subIndex === 0 && (
                        <>
                          <TableCell
                            rowSpan={data.subObjective.length}
                            sx={{
                              border: "1px solid",
                              padding: "4px",
                              width: { md: "20px", xl: "40px" }, // Adjust width dynamically
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontFamily: "Lucida Grande, sans-serif" }}
                            >
                              {data.serialNo}
                            </Typography>
                          </TableCell>
                          <TableCell
                            rowSpan={data.subObjective.length}
                            sx={{
                              border: "1px solid",
                              padding: "4px",
                              width: { md: "115px", xl: "120px" },
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontFamily: "Lucida Grande, sans-serif" }}
                            >
                              {data.Objective}
                            </Typography>
                          </TableCell>
                        </>
                      )}
                      <TableCell
                        sx={{
                          border: "1px solid",
                          padding: "4px",
                          width: { md: "250px", xl: "250px" }, // Adjust width dynamically
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontFamily: "Lucida Grande, sans-serif" }}
                        >
                          {sub.matrixTarget}
                        </Typography>
                      </TableCell>

                      {isNewFormDate && (
                        <TableCell
                          sx={{
                            border: "1px solid",
                            padding: "24px",
                            font: "small-caption",
                            fontFamily: "Lucida Grande, sans-serif",
                            width: { md: "30px", xl: "50px" }, // Adjust width dynamically
                          }}
                        >
                          {sub.weightage}%
                        </TableCell>
                      )}
                      <TableCell
                        sx={{
                          border: "1px solid",
                          padding: "4px",
                          fontFamily: "Lucida Grande, sans-serif",
                          width: { md: "130px", xl: "150px" }, // Adjust width dynamically
                        }}
                      >
                        <Stack direction={"row"} spacing={2}>
                          <Rating
                            name={`rating-${rowIndex}-${subIndex}`}
                            onChange={(event, newValue) =>
                              handleChange(   
                                rowIndex,
                                subIndex,
                                newValue,
                                sub.weightage
                              )
                            }
                            value={
                              ratings.find(
                                (r) =>
                                  r.rowIndex === rowIndex &&
                                  r.subIndex === subIndex
                              )?.rating || 0
                            }
                            readOnly={
                              isDisabled
                                ? isDisabled
                                : isSubmittedForRange &&
                                  selfratingCheckingFunction()
                            }
                            precision={0.5}
                          />
                          {isPreviousRatingPresent &&
                            (() => {
                              const currentRating =
                                ratings.find(
                                  (r) =>
                                    r.rowIndex === rowIndex &&
                                    r.subIndex === subIndex
                                )?.rating || 0;
                              const previousRating =
                                previousRatings.find(
                                  (r) =>
                                    r.rowIndex === rowIndex &&
                                    r.subIndex === subIndex
                                )?.rating || 0;
                              const difference = currentRating - previousRating;

                              if (difference !== 0) {
                                return (
                                  <Chip
                                    size="small"
                                    label={getChipLabel(difference)}
                                    sx={{
                                      fontSize: "12px",
                                      padding: "4px",
                                      height: "24px",
                                      borderRadius: "4px",
                                      ...getDifferenceChipStyle(difference),
                                    }}
                                  />
                                );
                              }
                              return null;
                            })()}
                        </Stack>
                      </TableCell>
                      {isNewFormDate && (
                        <>
                          {subIndex === 0 && (
                            <>
                              <TableCell
                                rowSpan={data.subObjective.length}
                                sx={{
                                  border: "1px solid",
                                  width: {
                                    md: "40px",
                                    xl: "80px",
                                  },
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontFamily: "Lucida Grande, sans-serif",
                                  }}
                                >
                                  {weightedRating(data.serialNo - 1)}
                                </Typography>
                              </TableCell>
                            </>
                          )}
                          <TableCell
                            sx={{
                              border: "1px solid",
                              padding: "2px",
                              fontFamily: "Lucida Grande, sans-serif",
                            }}
                          >
                            <textarea
                              style={{
                                width: "100%",
                                height: "70px",
                                resize: "none",
                                font: "inherit",
                                fontFamily: "Lucida Grande, sans-serif",
                                boxSizing: "border-box",
                              }}
                              value={
                                ratings.find(
                                  (r) =>
                                    r.rowIndex === rowIndex &&
                                    r.subIndex === subIndex
                                )?.comment || ""
                              }
                              disabled={
                                isDisabled
                                  ? isDisabled
                                  : isSubmittedForRange &&
                                    selfratingCheckingFunction()
                              }
                              onChange={(event) =>
                                handleCommentChange(
                                  rowIndex,
                                  subIndex,
                                  event.target.value
                                )
                              }
                            />
                              {validation[`${rowIndex}-${subIndex}`] && (
    <div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
      {validation[`${rowIndex}-${subIndex}`]} {/* Display the validation error for specific rowIndex and subIndex */}
    </div>
  )}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={isDialogOpen}>
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>
            The total rating given is
            <Typography variant="h6">
              {Math.floor(totalRatingSum * (20 / 100) * 100) / 100}.
            </Typography>
            The ratings submitted are unable to change once submitted. Do you
            want to continue?
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ color: "white" }}
              onClick={() => {
                setIsDialogOpen(false);
              }}
            >
              cancel
            </Button>
            <Button sx={{ color: "white" }} onClick={handleClick}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        {(role === "Admin" ||
          (role === "Project Manager" && astId !== user.associateId) ||
          ((role === "User" || role === "Project Manager") &&
            appraisaldata.projectManagerSubmission &&
            appraisaldata.userSideSubmission)) && (
          <TextField
            component="form"
            required={true}
            value={comments} // user submitted- user side disabled                   // project manager submitted - pm side disabled
            disabled={
              isDisabled
                ? isDisabled
                : isSubmittedForRange && selfratingCheckingFunction()
            }
            fullWidth
            type="text"
            multiline="true"
            rows="2"
            placeholder="Write your feedback here..."
            sx={{ mt: 2 }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              sx: {
                fontSize: "14px",
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "14px",
                },
              },
            }}
            onChange={(e) => setComments(e.target.value)}
          ></TextField>
        )}
      </CardContent>

      <CardActions sx={{ justifyContent: "flex-end" }}>
        {submitloading ? (
          <CircularProgress />
        ) : isDisabled ? (
          isDisabled
        ) : (!isSubmittedForRange && !submitted) ||
          !selfratingCheckingFunction() ? (
          <Grid>
            <Button
              size="small"
              component="form"
              onClick={() => {
                if (
                  appraisaldata?.commentObj === null &&
                  role === "Project Manager" &&
                  user?.associateId !== astId
                ) {
                  setSeverity("error");
                  setMessage(
                    "Please ensure the user completes their PERF form submission."
                  );
                  setSnackbarOpen(true);
                  return;
                }
                if (commentforEachObjective.length !== 16) {
                  setSeverity("error");
                  setMessage("Please fill every comment.");
                  setSnackbarOpen(true);
                  return;
                }
                if (validateRatings()) {
                  if (
                    !comments &&
                    (role === "Admin" ||
                      (role === "Project Manager" &&
                        astId !== user.associateId))
                  ) {
                    setSeverity("error");
                    setMessage("Please fill feedback for associate.");
                    setSnackbarOpen(true);
                    return;
                  }
                  if(validation){
                    setSeverity("error");
                    setMessage("Please fill the comments as per PERF Validations.");
                    setSnackbarOpen(true);
                    return;
                  }
                  setIsDialogOpen(true);
                }
              
              }}
              sx={{ color: "white", marginRight: "10px" }}
            >
              Submit
            </Button>
          </Grid>
        ) : null}
      </CardActions>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default UserAppraisal;