export const AUTH_USERNAME_FIELD_ID = 'username';
export const AUTH_PASSWORD_FIELD_ID = 'password';
export const AUTH_FIRSTNAME_FIELD_ID = 'firstname';
export const AUTH_LASTNAME_FIELD_ID = 'lastname';
export const AUTH_FULLNAME_FIELD_ID = 'fullname';
export const AUTH_PROFILEIMAGE_FIELD_ID = 'logo';
export const AUTH_DATE_OF_BIRTH_FIELD_ID ='dateOfBirth';
export const AUTH_EMAIL_FIELD_ID = 'email';

//dev -- change callback url in jira developer console
// export const FRONT_END_URL='https://uat.emportal.me/#/home'
// export const client_id= "Eexo7P4xoQy33LuFn36OEGsLMCeYOMNj"; 
// export const client_secret= "ATOAaF3AJdZzxzPyA6lSSOZd9VpELmDv8R-Y8ZnESLZnTksMdy844C2oB_ZMhYTeAziCF07B2FE2";
// export const redirect_uri= "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=Eexo7P4xoQy33LuFn36OEGsLMCeYOMNj&scope=read%3Ajira-work%20read%3Ajira-user%20write%3Ajira-work&redirect_uri=https%3A%2F%2Fuat.emportal.me%2F%23%2Fhome%2Ftimecard%2Ftimesheet&state=${YOUR_USER_BOUND_VALUE}&response_type=code&prompt=consent";

//test
// export const FRONT_END_URL='http://localhost:3000/#/home'
// export const client_id= "tBphbKm8SmgoVhCVIbjlptf7lxY0odKs"; 
// export const client_secret= "ATOA0wpmJ-XwptMxgU9N6GqcK11knammgTZh2hbaTxwoMDZpa2gJiMOiRato23SlFV84FC512805";
// export const redirect_uri= "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=tBphbKm8SmgoVhCVIbjlptf7lxY0odKs&scope=write%3Ajira-work%20read%3Ajira-user%20read%3Ajira-work&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F%23%2Fhome%2Ftimecard%2Ftimesheet&state=${YOUR_USER_BOUND_VALUE}&response_type=code&prompt=consent";

// //prod
export const FRONT_END_URL='https://emportal.me/#/home/timecard/timesheet';
export const client_id= "s4Edoc9vBQk85DiSZ8TATzr4n5fqNCGR"; 
export const client_secret= "ATOAEwz-xiN7MS4GBUvyCxZCRuiU6sSm5ITEZpE2X052PJoojEX4VcnPy9-p_byaJ66m224BB8BA";
export const redirect_uri= "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=s4Edoc9vBQk85DiSZ8TATzr4n5fqNCGR&scope=read%3Ajira-work%20read%3Ajira-user%20write%3Ajira-work&redirect_uri=https%3A%2F%2Femportal.me%2F%23%2Fhome%2Ftimecard%2Ftimesheet&state=${YOUR_USER_BOUND_VALUE}&response_type=code&prompt=consent";

export const AUTH_CONFRIM_PASSWORD_FIELD_ID = 'confirmPassword';
export const AUTH_GENDER_FIELD_ID = 'gender';
export const AUTH_ROLE_FIELD_ID = 'role';
export const AUTH_MOBILE_FIELD_ID = 'mobile';
export const AUTH_COMPANY_FIELD_ID = 'tenant';
export const AUTH_ID_FIELD_ID = 'associateId';
export const AUTH_OTP_FIELD_ID = 'otp';
export const AUTH_SEND_OTP = "GET_OTP";
export const AUTH_PROCEED_SIGNUP = "PROCEED_SIGNUP";

export const AUTH_SECURE_USER_DETAILS_LS_LEY = "_secure_user_details";
// export const AUTH_EMAIL_REGUALR_EXPRESSION = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
// export const AUTH_EMAIL_REGUALR_EXPRESSION = /^[a-zA-Z0-9+_.-]+@edvenswatech\.com$/;
export const AUTH_EMAIL_REGUALR_EXPRESSION = /^[a-zA-Z0-9+_.-]+@(edvenswa\.com|edvenswaepc\.com|uppuluri\.com|omninetworksinc\.com|snigdhaayurveda\.com|edvenswamotors\.com|edvenswainc\.com|edvenswatech\.com|edvenswa-tech\.com|edvenswapharma\.com|edvenswain\.onmicrosoft\.com)$/;
export const AUTH_PERSONALEMAIL_REGUALR_EXPRESSION = /^[a-zA-Z0-9+_.-]+@(gmail\.com|gmail\.in|gmail\.co)$/;
export const AUTH_PASSWORD_REGULAR_EXPRESSION = /^(?=.*[a-z])(?=.*[A-Z])(?=.*)(?=.*[0-9])(?=.*[@$!%*?&#])[A-Za-z0-9@$!%*?&#]{8,}$/;
export const AUTH_CAPTCHA_CHARACTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";


