import React, { useState } from 'react'
import ReportComponent from '../../edvenswa.emportal.reports/pages/ReportComponent'
import jsPDF from 'jspdf';
import { axiosInstance } from '../../../interceptors/AxiosInterceptor';
import moment from 'moment';
import { utils, write } from 'xlsx';
import saveAs from "file-saver";

const AdminScreenAppraisal = (props) => {

    const { onError, setLoad, user } = props;
    const [tenantId, setTenantId] = useState(user.tenantId);
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [appType, setAppType] = useState("Users");
    const [filteredElements, setFilteredElements] = useState([]);
    const [datefilter, setDateFilter] = useState("Last Bi-Weekly");
    // const [startDate, setStartDate] = useState(moment(new Date()).format('DD') > 15 ? moment(new Date()).startOf('month').add(15, 'day').format('YYYY-MM-DD') : moment(new Date()).startOf('month').format('YYYY-MM-DD'));
    // const [endDate, setEndDate] = useState(moment(new Date()).format('DD') > 15 ? moment(new Date()).endOf('month').format('YYYY-MM-DD') : moment(new Date()).startOf('month').add(14, 'days').format('YYYY-MM-DD'));


    const [startDate, setStartDate] = useState(moment(new Date()).format('DD') > 15 ? moment(new Date()).startOf('month').format('YYYY-MM-DD') : moment(new Date()).subtract(1, 'month').startOf('month').add(15, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('DD') > 15 ? moment(new Date()).startOf('month').add(14, 'days').format('YYYY-MM-DD') : moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));

    const [sortBy, setSortBy] = useState("ACTIVE");

    // startdate should be start of month or 16 th of the month compulsary
    //enddate should be 15th or end of month
    let arr = [];
    if ((moment(startDate).format('DD') === '01' || moment(startDate).format('DD') === '16') &&
        (moment(endDate).format('DD') === '15' || moment(endDate).format('DD') === moment(endDate).daysInMonth().toString())) {
        let count = 1
        for (
            let i = moment(startDate);
            moment(i).isSameOrBefore(endDate);
            i = moment(i).add(1, "day")
        ) {
            if (moment(i).format('DD') === '01' || moment(i).format('DD') === '15' || moment(i).format('DD') === '16' || moment(i).format('DD') === moment(i).daysInMonth().toString()) {
                count++;
                if (count % 2 == 0) {
                    arr.push(moment(i).format("DD/MMM"))
                }
                else {
                    arr.push(moment(i).format("DD/MMM/YYYY"))
                }
            }
        }
    }
    else {
        console.log('select dates correctly... startdate should start of month and enddate should be end of month ')
    }

    const handleDownloadReportExcel = async () => {
        try {
            if (appType === "Users") {
                const res = await axiosInstance.post(
                    `/user/getAllassociatesforRatings?sortBy=${sortBy}&tenantId=${tenantId}`,
                    { startDate, endDate }
                );
                const filteredElements = res.data;
                const astIds = filteredElements.map((user) => user?.associateId);
                let ratings = {};

                if (astIds.length > 0) {
                    try {
                        const ratingsResponse = await axiosInstance.put(
                            `/user/get/past/ratings?endDate=${endDate}`,
                            astIds
                        );
                        ratings = ratingsResponse?.data || {};
                    } catch (err) {
                        console.error("Error fetching ratings:", err);
                    }
                }

                const fileName = `All Associates Ratings Report.xlsx`;

                if (filteredElements.length > 0) {
                    const formattedData = filteredElements.map((row) => {
                        return {
                            "Ast ID": row?.associateId ? row?.associateId : "",
                            "Associate Name": row.firstname && row.lastname ? `${row.firstname} ${row.lastname}` : "",
                            "Projects": row.projectTitles,
                            "Project Manager": row?.projectManager?.fullname ?? "",
                            "Comments": "",
                            "UK Score": Math.floor(ratings?.[row?.associateId]?.finalRating * 180),
                            "userAppraislsList": row.userAppraisalsList ? row.userAppraisalsList : "",
                        };
                    });

                    let userArr = [];
                    let newObj = {};

                    for (let i = 0; i < arr.length; i++) {
                        if (i % 2 === 0) {
                            newObj = {
                                ...newObj,
                                [arr[i].concat(" - ").concat(arr[i + 1])]: 0,
                            };
                        }
                    }

                    formattedData.forEach((data) => {
                        let userObj = {};
                        let comment = "";

                        if (data["userAppraislsList"].length !== 0) {
                            let changedNewObj = { ...newObj };
                            data["userAppraislsList"].forEach((appraisal) => {
                                comment = appraisal.comments;
                                Object.keys(newObj).forEach((key) => {
                                    if (
                                        key ===
                                        moment(appraisal.startDate, "YYYY,MM,DD").format("DD/MMM").concat(" - ").concat(
                                            moment(appraisal.endDate, "YYYY,MM,DD").format("DD/MMM/YYYY")
                                        )
                                    ) {
                                        changedNewObj[key] = appraisal.ratings.toFixed(2);
                                    }
                                });
                            });
                            userObj = { ...data, ...changedNewObj, Comments: comment };
                        } else {
                            userObj = { ...data, ...newObj };
                        }
                        userArr.push(userObj);
                    });

                    const header = [
                        "Ast ID",
                        "Associate Name",
                        "Projects",
                        "Project Manager",
                        "Comments",
                        "UK Score",
                    ];
                    const newHeader = header.concat(Object.keys(newObj));

                    const userData = userArr.map((val) => newHeader.map((header) => val[header]));

                    // Create the worksheet and workbook
                    const worksheet = utils.aoa_to_sheet([newHeader, ...userData]);
                    const workbook = {
                        Sheets: { data: worksheet },
                        SheetNames: ["data"],
                    };

                    // Convert workbook to excel buffer
                    const excelBuffer = write(workbook, {
                        bookType: "xlsx",
                        type: "array",
                    });

                    // Create a Blob and trigger download
                    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                    const data = new Blob([excelBuffer], { type: fileType });
                    saveAs(data, fileName);
                }
            } else {
                const fileName = `Project_Managers_Not_Submitted_Ratings.xlsx`;
                const formattedData = filteredElements.map((row) => {
                    return {
                        "Ast ID": row?.associateId ? row?.associateId : "",
                        "Associate Name": row.firstname && row.lastname ? `${row.firstname} ${row.lastname}` : "",
                        Projects: row.projectTitles,
                    };
                });

                const header1 = [
                    { label: "Ast ID", key: "Ast ID" },
                    { label: "Associate Name", key: "Associate Name" },
                    { label: "Projects", key: "Projects" },
                ];

                const worksheet = utils.json_to_sheet(formattedData, {
                    header: header1.map((h) => h.label),
                });
                const workbook = {
                    Sheets: { data: worksheet },
                    SheetNames: ["data"],
                };

                const excelBuffer = write(workbook, {
                    bookType: "xlsx",
                    type: "array",
                });

                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const data = new Blob([excelBuffer], { type: fileType });
                saveAs(data, fileName);
            }
        } catch (err) {
            onError(err);
        }
    };



    const handleDownloadReport = async () => {
        if (appType === "Users") {
            try {
                const response = await axiosInstance.post(`/user/getAllassociatesforRatings?sortBy=${sortBy}&tenantId=${tenantId}`, {
                    startDate,
                    endDate,
                });
                const filteredElements = response.data;

                if (filteredElements.length > 0) {
                    // Fetch ratings for all associates
                    const astIds = filteredElements.map(user => user?.associateId);
                    let ratings = {};

                    if (astIds.length > 0) {
                        try {
                            const ratingsResponse = await axiosInstance.put(`/user/get/past/ratings?endDate=${endDate}`, astIds);
                            ratings = ratingsResponse?.data || {};
                        } catch (err) {
                            console.error("Error fetching ratings:", err);
                        }
                    }

                    // Proceed to generate the PDF
                    const pdfWidth = 210;
                    const pdfHeight = 250;
                    const pdf = new jsPDF({
                        orientation: "landscape",
                        unit: "mm",
                        format: [pdfWidth, pdfHeight],
                    });

                    const logoUrl = 'https://tse3.mm.bing.net/th?id=OIP.xrnfPsu-nIEVf0HzuyWt3AAAAA&pid=Api&P=0&h=180';
                    const centerX = pdf.internal.pageSize.width / 2;
                    const imageWidth = 20;
                    const imageHeight = 20;
                    const imageY = 2;

                    pdf.addImage(logoUrl, centerX - imageWidth / 2, imageY, imageWidth, imageHeight);

                    const formattedData = filteredElements.map((row) => {
                        const ukScore = ratings?.[row?.associateId]?.finalRating
                            ? Math.floor(ratings[row.associateId].finalRating * 180)
                            : 0;

                        return {
                            "Ast ID": row?.associateId || "",
                            "Associate Name": row?.firstname && row?.lastname ? `${row.firstname} ${row.lastname}` : "",
                            "Projects": row?.projectTitles || "",
                            "UK Score": ukScore,
                        };
                    });

                    const header = ["Ast ID", "Associate Name", "Projects", "UK Score"];

                    const userData = formattedData.map((val) => [
                        val["Ast ID"],
                        val["Associate Name"],
                        val["Projects"],
                        val["UK Score"],
                    ]);

                    const tableStartY = imageY + imageHeight + 20; //44

                    pdf.autoTable({
                        head: [header],
                        body: userData,
                        margin: { left: 8, right: 8, bottom: 10 },
                        startY: 58,
                        rowPageBreak: "avoid",
                        headStyles: { fillColor: "#008080", textColor: "#ffffff", fontStyle: "bold" },
                        didDrawPage: function (data) {
                            if (data.pageNumber === 1) {
                                pdf.setFont("Times New Roman, Times, serif");
                                pdf.text(`Dear Admin, Following is the report of All Users.`, 12, tableStartY - 6);
                                pdf.setFontSize(13);
                                pdf.text(
                                    `For the time period of ${moment(startDate).format("DD-MMM-yyyy")} to ${moment(endDate).format("DD-MMM-yyyy")} `,
                                    12,
                                    46
                                );
                            }
                        },
                        didParseCell: function (data) {
                            data.cell.styles.lineWidth = 0.1;
                            data.cell.styles.lineColor = [0, 0, 0];
                        },
                    });

                    pdf.save(`AllAssociateRatings.pdf`);
                }
            } catch (err) {
                console.error("Error generating report:", err);
            }
        } else {
            console.warn("Non-user report type handling is not implemented.");
        }
    };




    return (
        <div>
            <ReportComponent {...props}
                type={"Appraisals"}
                handleDownloadReport={handleDownloadReport}
                handleDownloadReportExcel={handleDownloadReportExcel}
                setStartDate={setStartDate}
                startDate={startDate}
                endDate={endDate}
                setDateFilter={setDateFilter}
                setEndDate={setEndDate}
                startDateError={startDateError}
                endDateError={endDateError}
                setStartDateError={setStartDateError}
                setEndDateError={setEndDateError}
                sortBy={sortBy}
                appType={appType}
                setFilteredElements={setFilteredElements}
                setAppType={setAppType}
                setSortBy={setSortBy}
                displayAdd={false}
            />
        </div>
    )
}

export default AdminScreenAppraisal
