import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import UserAppraisal from "../../edvenswa.emportal.UserAppraisal/pages/UserAppraisal";
import "../css/ratings.css";

const PastRatingsCustomCell = (props) => {
  const {
    ratings,
    type,
    astId,
    setStartDate,
    setEndDate,
    isAppraisalPage,
    project,
    user,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);

  const getColorClass = (rating) => {
    switch (rating) {
      case 1:
        return "green-ball";
      case 0:
        return "orange-ball";
      case -1:
        return "red-ball";
      default:
        return "";
    }
  };

  const handleClick = (event, startDate, endDate) => {
    event.preventDefault();
    if (isAppraisalPage) {
      setStartDate(startDate);
      setEndDate(endDate);
    }

    // Set dialog data and open dialog
    setDialogData({
      startDate,
      endDate,
      astId,
      project,
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData(null);
  };

  const showRating = (rating) => {
    const truncatedScore = Math.floor(rating * 100) / 100;
    const formattedScore = truncatedScore.toFixed(2);
    return formattedScore;
  };

  return (
    <div
      className="ratings-container"
      style={{
        marginTop: !type && "11px",
        display: "flex", // Enable flexbox
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        gap: "5px",
      }}
    >
      {ratings &&
        ratings?.["binaryRatings"] &&
        ratings?.["binaryRatings"].map((rating, index) => {
          const formatToDDMMYY = (dateString) => {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = String(date.getFullYear()).slice(-2);
            return `${day}/${month}/${year}`;
          };

          const startDate = formatToDDMMYY(
            ratings?.["dates"][index]?.startDate
          );
          const endDate = formatToDDMMYY(ratings?.["dates"][index]?.endDate);

          const baseSize = 15;
          const sizeDecrement = 1;
          const size = baseSize - (index + 1) * sizeDecrement;

          return (
            <Tooltip
              key={index}
              title={
                <>
                  {`${startDate} - ${endDate}`}
                  <br />
                  {`Rating: ${showRating(ratings?.["ratings"][index])}`}
                </>
              }
            >
              <span
                onClick={(e) =>
                  handleClick(
                    e,
                    ratings?.["dates"][index]?.startDate,
                    ratings?.["dates"][index]?.endDate
                  )
                }
                className={getColorClass(rating)}
                style={{
                  width: `${size}px`,
                  height: `${size}px`,
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              ></span>
            </Tooltip>
          );
        })}

      {/* Dialog for User Appraisal */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>User Appraisal</DialogTitle>
        <DialogContent>
          {dialogData && (
            <UserAppraisal
              isDisabled={true}
              showDateRange={false}
              user={user}
              startDate={dialogData.startDate}
              endDate={dialogData.endDate}
              astId={dialogData.astId}
              project={dialogData.project}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Grid alignContent={"end"}>
            <Button
              size="small"
              variant="contained"
              onClick={handleCloseDialog}
            >
              Close
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PastRatingsCustomCell;
