// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import DownloadIcon from "@mui/icons-material/Download";
import { Alert, Box, Button, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Menu, MenuItem, Snackbar, Stack, Table, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
// import {
//     CategoryScale,
//     Chart as ChartJS,
//     Tooltip as ChartTooltip,
//     Legend,
//     LinearScale,
//     LineElement,
//     PointElement,
//     Title,
// } from 'chart.js';
// import { saveAs } from 'file-saver';
// import jsPDF from 'jspdf';
// import moment from 'moment';
// import React, { useEffect, useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import * as XLSX from 'xlsx';
// import { axiosInstance } from '../../../interceptors/AxiosInterceptor';

// ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, ChartTooltip, Title, Legend);






export const OldUserAppraisal = [
    {
        serialNo: 1,
        Objective: 'Project',
        subObjective: [
            { desc: 'Project', matrixTarget: 'Delivered the assigned task/assignment on time.' },
            { matrixTarget: 'Delivery with proper documentation.' },
            { matrixTarget: 'Business Solution & improvements suggested' },
            { matrixTarget: 'Helping other team members/ projects beyond assigned tasks' },
            { desc: '', matrixTarget: 'Timely Communication and follow-up' },
            { desc: '', matrixTarget: 'Quality of Work' },
        ],
        rating: [
            { sno: 1, ratingValue: 1 },
            { sno: 2, ratingValue: 3 }
        ]
    },
    {
        serialNo: 2,
        Objective: 'Exceptional Contribution',
        subObjective: [
            { desc: 'Exceptional Contribution', matrixTarget: 'Exceptional work done in the project or for practice or for an organization that resulted improvement in Business process, and example reduction in cost, usage of new technology, time saving or generated a reusable component' },
        ],
        rating: [1, 2, 3, 4, 5, 6]
    },
    {
        serialNo: 3,
        Objective: 'Trainings & Talent Development',
        subObjective: [
            { desc: 'Trainings & Talent Development', matrixTarget: 'Learn atleast one new concept that is aligned to Quarterly learning goal' },
        ],
        rating: [
            { sno: 1, ratingValue: 4 }
        ]
    },
    {
        serialNo: 4,
        Objective: 'JIRA and Emportal',
        subObjective: [
            { desc: 'JIRA and Emportal', matrixTarget: 'Timely creation/updation of JIRA Stories and tasks' },
            { desc: '', matrixTarget: 'Timely submission of Weekly Emportal timesheets' },
            { desc: '', matrixTarget: 'Timely submission of leaves and remote clock-in in Keka' },
        ],
        rating: [1, 2, 3]
    },

]

export const OldTableheader = () => {
    return <TableHead>
        <TableRow>
            <TableCell sx={{ border: '1px solid', padding: '4px', backgroundColor: '#dde2eb' }}>
                <Typography sx={{ fontFamily: 'Lucida Grande, sans-serif', fontWeight: 'bold' }}>S.no</Typography>
            </TableCell>
            <TableCell sx={{ border: '1px solid', padding: '4px', backgroundColor: '#dde2eb' }}>
                <Typography sx={{ fontFamily: 'Lucida Grande, sans-serif', fontWeight: 'bold' }}>Description</Typography>
            </TableCell>
            <TableCell sx={{ border: '1px solid', padding: '4px', backgroundColor: '#dde2eb' }}>
                <Typography sx={{ fontFamily: 'Lucida Grande, sans-serif', fontWeight: 'bold' }}>Matrix & Target</Typography>
            </TableCell>
            <TableCell sx={{ border: '1px solid', padding: '4px', backgroundColor: '#dde2eb' }}>
                <Typography sx={{ fontFamily: 'Lucida Grande, sans-serif', fontWeight: 'bold' }}>Rating(1-5)Low to High</Typography>
            </TableCell>
        </TableRow>
    </TableHead>
}


// const UserAppraisal = (props) => {
//     const { user } = props;
//     const role = user.roles[0]
//     const [ratings, setRatings] = useState([]);
//     const [appraisaldata, setAppraisalData] = useState({ comments: '' });
//     const [isDialogOpen, setIsDialogOpen] = useState(false);
//     const [submitted, setSubmitted] = useState(false);
//     const [isSubmittedForRange, setIsSubmittedForRange] = useState(false);
//     const location = useLocation();
//     const [menuanchorEl, setMenuAnchorEl] = useState(null);
//     const [comments, setComments] = useState('');
//     const [snackbarOpen, setSnackbarOpen] = useState(false);
//     const [severity, setSeverity] = useState('success', 'error');
//     const [message, setMessage] = useState('');
//     const [submitloading, setSubmitLoading] = useState(false);
//     const [pastRatings, setPastRatings] = useState({});
//     const [projectManager, setProjectManager] = useState({});
//     const navigate = useNavigate();
//     const [isPreviousRatingPresent, setIsPreviousRatingsPresent] = useState(false);
//     const [previousRatings, setPreviousRatings] = useState([]);
//     const [teamRatings, setTeamRatings] = useState({});
//     const [companyRatings, setCompanyRatings] = useState({});
//     // const [startDate, setStartDate] = useState(location?.state ? location.state.startDate : moment(new Date()).format('DD') > 15 ? moment(new Date()).startOf('month').add(15, 'day').format('YYYY-MM-DD') : moment(new Date()).startOf('month').format('YYYY-MM-DD'));
//     // const [endDate, setEndDate] = useState(location?.state ? location.state.endDate : moment(new Date()).format('DD') > 15 ? moment(new Date()).endOf('month').format('YYYY-MM-DD') : moment(new Date()).startOf('month').add(14, 'days').format('YYYY-MM-DD'));
//     const astId = props?.astId ?? location?.state?.astId;
//     const project = props?.project ?? location?.state?.project;
//     const [startDate, setStartDate] = useState(location?.state?.startDate ?? props?.startDate);
//     const [endDate, setEndDate] = useState(location?.state?.endDate ?? props?.endDate);

//     useEffect(() => {
//         axiosInstance
//             .get(`/user/company/ratings?endDate=${endDate}`)
//             .then(res => setCompanyRatings(res?.data))
//             .catch(err => console.log(err));
//         setIsPreviousRatingsPresent(false);
//         setPreviousRatings([]);
//         if (astId) {
//             axiosInstance
//                 .put(`/user/get/past/ratings?endDate=${endDate}`, [astId])
//                 .then(res => setPastRatings(res?.data))
//                 .catch(err => console.log(err));
//         }
//         if (project) {
//             axiosInstance
//                 .get(`/user/team/ratings?endDate=${endDate}&project=${project}`)
//                 .then(res => setTeamRatings(res?.data))
//                 .catch(err => console.log(err));
//         }

//         axiosInstance.put("/user/getAssociateRatings", {
//             startDate: startDate,
//             endDate: endDate,
//             user: { associateId: astId },
//             project: { title: project }
//         })
//             .then(res => {
//                 setAppraisalData(res.data?.[0] ?? {})
//                 if (res.data?.[0]?.ratingsObj !== null) {
//                     setComments(res.data?.[0]?.comments)
//                     setRatings(JSON.parse(res.data?.[0]?.ratingsObj))
//                     setIsSubmittedForRange(true);
//                 }
//                 else {
//                     setIsSubmittedForRange(false);

//                 }
//                 if (res?.data?.[1] && res.data?.[1]?.ratingsObj !== null) {
//                     setPreviousRatings(JSON.parse(res.data?.[1]?.ratingsObj))
//                     setIsPreviousRatingsPresent(true);
//                 }

//             }).catch(err => {
//                 console.log(err);

//             })

//     }, [startDate, endDate, submitted]);

//     const calculateAverageRatings = (teamRatings) => {
//         const dateRatingsMap = {};

//         Object.values(teamRatings).forEach(({ dates, ratings }) => {
//             dates.forEach((date, index) => {
//                 const range = `${moment(date.startDate).format("MMM D")} - ${moment(date.endDate).format("MMM D")}`;
//                 if (!dateRatingsMap[range]) {
//                     dateRatingsMap[range] = [];
//                 }
//                 dateRatingsMap[range].push(ratings[index]);
//             });
//         });

//         const averages = Object.keys(dateRatingsMap).map((range) => {
//             const ratings = dateRatingsMap[range];
//             const average = ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length;
//             return { range, average: parseFloat(average.toFixed(2)) };
//         });

//         return averages;
//     };
//     const averageRatings = calculateAverageRatings(teamRatings);
//     const averageCompanyRatings = calculateAverageRatings(companyRatings);

//     const labels = pastRatings?.[astId]?.dates?.map((date) => {
//         const startDate = moment(date.startDate).format("MMM DD");
//         const month = moment(date.startDate).format("MMM");
//         const endDate = moment(date.endDate).format("DD");
//         if (!`${startDate} - ${endDate}`.includes("16")) {
//             return month;
//         }
//         return "";
//     });

//     const options = {
//         responsive: true,
//         plugins: {
//             legend: {
//                 labels: {
//                     font: {
//                         size: 10, // Adjust font size here (e.g., 10px)
//                     },
//                     boxWidth: 14, // Adjust the size of the color box (square)
//                     padding: 10, // Space around the legend items
//                 },
//             },
//             tooltip: {
//                 callbacks: {
//                     label: function (tooltipItem) {
//                         const datasetIndex = tooltipItem.datasetIndex;
//                         const dataIndex = tooltipItem.dataIndex;
//                         let startDate;
//                         let endDate;
//                         const dateRange = pastRatings?.[astId]?.dates[dataIndex];
//                         startDate = moment(dateRange.startDate).format("MMM DD");
//                         endDate = moment(dateRange.endDate).format("DD");
//                         if (datasetIndex === 0) {
//                             const rating = pastRatings?.[astId]?.ratings[dataIndex];

//                             if (dateRange && rating !== undefined) {
//                                 return `Range: ${startDate} - ${endDate}, My Rating: ${rating}`;
//                             }
//                         } else if (datasetIndex === 1) {
//                             const rating = averageRatings[dataIndex]?.average;

//                             if (rating !== undefined) {
//                                 return `Range: ${startDate} - ${endDate}, Team Rating: ${rating}`;
//                             }
//                         }
//                         else {
//                             const rating = averageCompanyRatings[dataIndex]?.average;

//                             if (rating !== undefined) {
//                                 return `Range: ${startDate} - ${endDate}, Company Rating: ${rating}`;
//                             }
//                         }
//                         return '';
//                     },
//                 },
//             },
//         },
//         scales: {
//             x: {
//                 title: {
//                     display: true,
//                     text: "Date Range",
//                 },
//             },
//             y: {
//                 title: {
//                     display: true,
//                     text: "Ratings",
//                 },
//                 ticks: {
//                     stepSize: 1,
//                 },
//             },
//         },
//     };

//     const chartData = {
//         labels,
//         datasets: [
//             {
//                 label: 'My Rating',
//                 data: pastRatings?.[astId]?.ratings,
//                 borderColor: 'rgba(75, 192, 192, 1)',
//                 backgroundColor: 'rgba(75, 192, 192, 1)',
//                 borderWidth: 2,
//                 tension: 0.4,
//                 pointRadius: pastRatings?.[astId]?.dates.map(date =>
//                     date.startDate === startDate || date.endDate === startDate ? 8 : 5
//                 ),
//                 pointBackgroundColor: 'rgba(75, 192, 192, 1)'
//             },
//             {
//                 label: 'Team Rating',
//                 data: averageRatings.map((data) => data.average),
//                 borderColor: 'purple',
//                 backgroundColor: 'purple',
//                 borderWidth: 2,
//                 tension: 0.4,
//                 pointRadius: pastRatings?.[astId]?.dates.map(date =>
//                     date.startDate === startDate || date.endDate === startDate ? 8 : 5
//                 ),
//                 pointBackgroundColor: 'purple'
//             },
//             {
//                 label: 'Company Rating',
//                 data: averageCompanyRatings.map((data) => data.average),
//                 borderColor: 'orange',
//                 backgroundColor: 'orange',
//                 borderWidth: 2,
//                 tension: 0.4,
//                 pointRadius: pastRatings?.[astId]?.dates.map(date =>
//                     date.startDate === startDate || date.endDate === startDate ? 8 : 5
//                 ),
//                 pointBackgroundColor: 'orange'
//             },
//         ],
//     };



//     const UserAppraisal = [
//         {
//             serialNo: 1,
//             Objective: 'Capability',
//             subObjective: [
//                 { desc: 'Capability', matrixTarget: 'Delivered the assigned task/assignment on time.' },
//                 { matrixTarget: 'Delivery with proper documentation.' },
//                 { matrixTarget: 'Business Solution & improvements suggested' },
//                 { matrixTarget: 'Helping other team members/ projects beyond assigned tasks' },
//                 { desc: '', matrixTarget: 'Timely Communication and follow-up' },
//                 { desc: '', matrixTarget: 'Quality of Work' },
//             ],
//             rating: [
//                 { sno: 1, ratingValue: 1 },
//                 { sno: 2, ratingValue: 3 }
//             ]
//         },
//         {
//             serialNo: 2,
//             Objective: 'Creativity',
//             subObjective: [
//                 { desc: 'Creativity', matrixTarget: 'Exceptional work done in the project or for practice or for an organization that resulted improvement in Business process, and example reduction in cost, usage of new technology, time saving or generated a reusable component' },
//             ],
//             rating: [1, 2, 3, 4, 5, 6]
//         },
//         {
//             serialNo: 3,
//             Objective: 'Collaboration',
//             subObjective: [
//                 { desc: 'Collaboration', matrixTarget: 'Learn atleast one new concept that is aligned to Quarterly learning goal' },
//             ],
//             rating: [
//                 { sno: 1, ratingValue: 4 }
//             ]
//         },
//         {
//             serialNo: 4,
//             Objective: 'Compliance',
//             subObjective: [
//                 { desc: 'Compliance', matrixTarget: 'Timely creation/updation of JIRA Stories and tasks' },
//                 { desc: '', matrixTarget: 'Timely submission of Weekly Emportal timesheets' },
//                 { desc: '', matrixTarget: 'Timely submission of leaves and remote clock-in in Keka' },
//             ],
//             rating: [1, 2, 3]
//         },

//     ]

//     // let ratingObj = {}
//     // const handleChange = (rowIndex, index, rating) => {
//     //     ratingObj = { ...ratingObj, rowIndex: rowIndex, subIndex: index, rating: rating }

//     //     setRatings((arr) => {
//     //         const isAlreadyExisted = arr.find((rObj) => rObj.rowIndex === ratingObj.rowIndex && rObj.subIndex === ratingObj.subIndex)
//     //         if (isAlreadyExisted) {
//     //             arr.pop(isAlreadyExisted);
//     //             arr.push(ratingObj);
//     //         }
//     //         else {
//     //             arr.push(ratingObj);
//     //         }
//     //         return arr;
//     //     })

//     // }
//     const handleDownloadPDF = () => {
//         const pdfWidth = 210;
//         const pdfHeight = 250;
//         const pdf = new jsPDF({
//             orientation: "landscape",
//             unit: "mm",
//             format: [pdfWidth, pdfHeight],
//         });

//         const logoUrl1 = 'https://tse3.mm.bing.net/th?id=OIP.xrnfPsu-nIEVf0HzuyWt3AAAAA&pid=Api&P=0&h=180';
//         const logoUrl2 = 'https://media-hosting.imagekit.io//345db9943dbb45b1/emportal-me%201%20(1).png?Expires=1734776498&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=bjE--syrSN-EUHcJ0ul6NP0lVCNiAePshUfvsW2QXdgV4iUjgcLO7CEEJiHCGN1DX4-fiz2~k4tTl-LXw2Iqc30dbkED~de1n4FD8zQ1kWk6l8tZCFNIjQtJ9y6~0ntCjMo5t0zEqVLiRmeBeBOy8kZDJB40JyTnh8iGCOPm-~xVStSpApjmkpyKRuYlaN8qtKHBIlC6zdcFHl0er4nQzG31RGeZA5DhmZdygWxYOrl7WsbuIWpGb~ns4ATBlq~p9z7z3xGZ7iMLjM3evz~qkpIvlScRdi~mqgcjTi8l3iImy8X~e3LUGLL61twZ0X6MbmUt4svCY-LfOdoNVNd0oA__';

//         const pageWidth = pdf.internal.pageSize.width;
//         const margin = 10;

//         const imageWidth1 = 30;
//         const imageHeight1 = 25;
//         const logoY = 10;

//         pdf.addImage(logoUrl1, 'PNG', margin, logoY, imageWidth1, imageHeight1);


//         const originalWidth = 345;
//         const originalHeight = 94;
//         const maxLogoWidth = 30;
//         const scale = maxLogoWidth / originalWidth;

//         const imageWidth2 = originalWidth * scale;
//         const imageHeight2 = originalHeight * scale;

//         const logoX2 = pageWidth - imageWidth2 - margin;

//         pdf.addImage(logoUrl2, 'PNG', logoX2, logoY, imageWidth2, imageHeight2);

//         const titleY = logoY + Math.max(imageHeight1, imageHeight2) + 10;
//         pdf.setFont("Times New Roman, Times, serif");
//         pdf.setFontSize(17);
//         const title = `Performance Evaluation, Rating & Feedback of ${location.state.fullname}`;
//         pdf.text(title, pageWidth / 2, titleY, { align: 'center' });

//         const chartCanvas = document.querySelector('canvas');
//         const chartImage = chartCanvas.toDataURL('image/png');

//         const chartWidth = 80;
//         const chartHeight = 50;
//         const chartX = pageWidth - chartWidth - margin;
//         const chartY = titleY + 10;

//         pdf.addImage(chartImage, 'PNG', chartX, chartY, chartWidth, chartHeight);


//         const detailsY = chartY;
//         pdf.setFontSize(12);

//         const lineSpacing = 8;
//         const details = [
//             `Associate ID : ${appraisaldata.associateId}`,
//             `Full Name : ${appraisaldata.fullname}`,
//             `Designation : ${appraisaldata.designation}`,
//             `Project Manager : ${appraisaldata.projectManager.fullname}`,
//             `Uk Rating : ${(totalRatingSum / 11).toFixed(2)}`,
//             `Feedback : ${comments || 'No comments available'}`,
//         ];

//         const maxWidth = 130;

//         details.forEach((detail, index) => {
//             const wrappedText = pdf.splitTextToSize(detail, maxWidth);
//             pdf.text(wrappedText, margin, detailsY + index * lineSpacing);
//         });

//         const tableStartY = Math.max(chartY + chartHeight + 10, detailsY + details.length * lineSpacing + 10);

//         const splitData = UserAppraisal.flatMap((data, rowIndex) => {
//             const descriptions = data.subObjective.map(sub => sub.desc || '').join('\n').split('\n');
//             const matrixTargets = data.subObjective.map(sub => sub.matrixTarget || '').join('\n').split('\n');
//             const ratingsDemo = data.subObjective.map((sub, subIndex) => (ratings.find(r => r.rowIndex === rowIndex && r.subIndex === subIndex)?.rating || 0)).join('\n').split('\n');

//             const maxRows = Math.max(descriptions.length, matrixTargets.length, ratingsDemo.length);
//             return Array.from({ length: maxRows }, (_, i) => ({
//                 "s.no": data.serialNo,
//                 "Description": descriptions[i] || '',
//                 "Matrix & Target": matrixTargets[i] || '',
//                 "Rating (1-5)Low to High": ratingsDemo[i] || '',
//             }));
//         });

//         const header = ['s.no', 'Description', 'Matrix & Target', 'Rating (1-5)Low to High'];

//         const userData = splitData.map(val => header.map(header => val[header]));

//         pdf.autoTable({
//             head: [header],
//             body: userData,
//             margin: { left: 8, right: 8 },
//             startY: tableStartY,
//             rowPageBreak: 'avoid',
//             headStyles: { fillColor: "#008080", textColor: "#ffffff", fontStyle: "bold", fontSize: 10 },
//             bodyStyles: {
//                 fontSize: 11,
//             },
//             didDrawPage: function (data) {
//                 const now = new Date();
//                 const timestamp = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
//                 pdf.setFontSize(10);
//                 pdf.text(
//                     `Generated on: ${timestamp} | emportal.me`,
//                     pageWidth / 2,
//                     pdf.internal.pageSize.height - 5,
//                     { align: 'center' }
//                 );
//             },
//         });

//         pdf.save(`${location.state.fullname}.pdf`);
//     };





//     const handleDownloadExcel = () => {
//         const wb = XLSX.utils.book_new();
//         const ws = XLSX.utils.json_to_sheet([]);

//         const splitData = UserAppraisal.flatMap((data, rowIndex) => {
//             const descriptions = data.subObjective.map(sub => sub.desc || '').join('\n').split('\n');
//             const matrixTargets = data.subObjective.map(sub => sub.matrixTarget || '').join('\n').split('\n');
//             const ratingsDemo = data.subObjective.map((sub, subIndex) => (ratings.find(r => r.rowIndex === rowIndex && r.subIndex === subIndex)?.rating || 0)).join('\n').split('\n');

//             const maxRows = Math.max(descriptions.length, matrixTargets.length, ratingsDemo.length);
//             return Array.from({ length: maxRows }, (_, i) => ({
//                 "s.no": data.serialNo,
//                 "Description": descriptions[i] || '',
//                 "Matrix & Target": matrixTargets[i] || '',
//                 "Rating (1-5)Low to High": ratingsDemo[i] || '',
//             }));
//         });

//         const header = [
//             's.no',
//             // "Objective",
//             "Description",
//             "Matrix & Target",
//             "Rating (1-5)Low to High",
//         ];

//         XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
//         splitData.forEach((data, index) => {
//             const rowData = header.map(header => data[header]);
//             XLSX.utils.sheet_add_aoa(ws, [rowData], { origin: `A${index + 2}` });
//         });

//         const columnWidths = header.map((col) => ({ wch: col.length + 10 }));
//         ws['!cols'] = columnWidths;
//         XLSX.utils.book_append_sheet(wb, ws, 'Appraisal Report');

//         const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//         const excelBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
//         saveAs(excelBlob, `${location.state.fullname}.xlsx`);
//     };


//     const handleChange = (rowIndex, subIndex, newValue) => {
//         setRatings(prevRatings => {
//             // Update existing ratings or add new one
//             const updatedRatings = prevRatings.map(r =>
//                 r.rowIndex === rowIndex && r.subIndex === subIndex
//                     ? { ...r, rating: newValue } // Update existing rating
//                     : r
//             );

//             // If no rating was updated, add a new entry
//             if (!updatedRatings.some(r => r.rowIndex === rowIndex && r.subIndex === subIndex)) {
//                 updatedRatings.push({ rowIndex, subIndex, rating: newValue });
//             }
//             return updatedRatings;
//         });
//     };

//     const handleDownloadMenuClick = (event) => {
//         setMenuAnchorEl(event.currentTarget);
//     };

//     let totalRatingSum = 0;
//     ratings.map((r) =>
//         totalRatingSum += r.rating
//     )


//     const handlePrevDateChange = (e, type) => {
//         setRatings([])
//         setComments('')
//         setIsSubmittedForRange(false);
//         setSubmitted(false);
//         if (type === "icon") {
//             const newStartDate = moment(startDate).format('DD').toString() === '01' ? moment(startDate).subtract(1, 'month').startOf('month').add(15, 'days').format('YYYY-MM-DD') : moment(startDate).startOf('month').format('YYYY-MM-DD');
//             const newEndDate = moment(startDate).format('DD').toString() === '01' ? moment(startDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD') : moment(startDate).startOf('month').add(14, 'day').format('YYYY-MM-DD');
//             setStartDate(newStartDate);
//             setEndDate(newEndDate);
//         }
//         else {
//             const value = e.target.value;
//             setStartDate(value);
//         }
//     };
//     const handleNextDateChange = (e, type) => {
//         setRatings([])
//         setComments('')
//         setIsSubmittedForRange(false);
//         setSubmitted(false);
//         if (type === "icon") {
//             const newStartDate = moment(startDate).format('DD').toString() === '01' ? moment(startDate).add(15, 'days').format('YYYY-MM-DD') : moment(startDate).add(1, 'month').startOf('month').format('YYYY-MM-DD');
//             const newEndDate = moment(startDate).format('DD').toString() === '01' ? moment(startDate).endOf('month').format('YYYY-MM-DD') : moment(startDate).add(1, 'month').startOf('month').add(14, 'days').format('YYYY-MM-DD');
//             setStartDate(newStartDate);
//             setEndDate(newEndDate);
//         }
//         else {
//             const value = e.target.value;
//             setEndDate(value);
//         }
//     };


//     const renderDateFields = () => {
//         return (
//             <Box sx={{ display: "flex" }}>
//                 <Tooltip title="click to go to previous month">
//                     {location.state.type === "User" ? <></> : <IconButton onClick={(e) => handlePrevDateChange(e, "icon")}>
//                         <ArrowBackIosIcon
//                             fontSize="small"
//                         />
//                     </IconButton>}
//                 </Tooltip>
//                 <TextField
//                     type="date"
//                     value={startDate}
//                     label="Start Date"
//                     size="small"
//                     error={
//                         startDate &&
//                             new Date(startDate) > new Date(endDate)
//                             ? "Start date cannot be after end date"
//                             : ""
//                     }
//                     helperText={
//                         startDate &&
//                             new Date(startDate) > new Date(endDate)
//                             ? "Start date cannot be after end date"
//                             : ""
//                     }
//                     InputLabelProps={{
//                         style: { font: "small-caption", fontFamily: "Lucida Grande" },
//                         shrink: true,
//                     }}
//                     InputProps={{
//                         style: { font: "small-caption", fontFamily: "Lucida Grande" },
//                     }}
//                     disabled={true}
//                     onChange={handlePrevDateChange}
//                 />
//                 &nbsp; &nbsp;
//                 <TextField
//                     type="date"
//                     value={endDate}
//                     label="End Date"
//                     size="small"
//                     error={
//                         startDate &&
//                             new Date(endDate) < new Date(startDate)
//                             ? "End date cannot be before start date"
//                             : ""
//                     }
//                     helperText={
//                         startDate &&
//                             new Date(endDate) < new Date(startDate)
//                             ? "End date cannot be before start date"
//                             : ""
//                     }
//                     InputLabelProps={{
//                         style: { font: "small-caption", fontFamily: "Lucida Grande" },
//                         shrink: true,
//                     }}
//                     InputProps={{
//                         style: { font: "small-caption", fontFamily: "Lucida Grande" },
//                     }}
//                     disabled={true}
//                     onChange={handleNextDateChange}
//                 />
//                 <Tooltip title="click to go to next month">
//                     {location.state.type === "User" ? <></> : <IconButton
//                         onClick={(e) => handleNextDateChange(e, "icon")}
//                         disabled={
//                             new Date(endDate) >= new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
//                         }>
//                         <ArrowForwardIosIcon
//                             fontSize="small"
//                         />
//                     </IconButton>}
//                 </Tooltip>
//             </Box>
//         );
//     }


//     const handleClick = () => {
//         setIsDialogOpen(false);
//         const data = {
//             ratings: JSON.stringify(ratings),
//             comments: comments,
//             user: { associateId: location.state?.username },
//             project: { id: role === "Reporting Manager" ? props.projects?.id : props.projects.find(data => data.title === location.state.project).id },
//             startDate: startDate,
//             endDate: endDate
//         }
//         setSubmitLoading(true)
//         if (ratings.length !== 0) {
//             axiosInstance.post("/user/submitRatings", data).then(res => {
//                 setSubmitted(true);
//                 setIsSubmittedForRange(true);
//                 setSeverity('success');
//                 setMessage("Ratings Submitted Successfully!");
//                 setSnackbarOpen(true);
//                 setSubmitLoading(false)
//             }).catch(err => {
//                 console.log(err);
//                 setSubmitLoading(false)
//             })
//         }
//         else {
//             setSeverity('error');
//             setMessage("Provide atleast 1 rating. Please try again.");
//             setSnackbarOpen(true);
//             setSubmitLoading(false)
//         }


//     }


//     const validateRatings = () => {
//         for (let rowIndex = 0; rowIndex < UserAppraisal.length; rowIndex++) {
//             const subObjectives = UserAppraisal[rowIndex].subObjective;

//             for (let subIndex = 0; subIndex < subObjectives.length; subIndex++) {
//                 const rating = ratings.find(
//                     r => r.rowIndex === rowIndex && r.subIndex === subIndex
//                 )?.rating;

//                 if (!rating || rating <= 0) {
//                     setSeverity('error');
//                     setMessage(
//                         " Please provide Ratings for all the fields."
//                     );
//                     setSnackbarOpen(true);
//                     return false;
//                 }
//             }
//         }

//         return true;
//     };

//     const getDifferenceChipStyle = (difference) => {
//         if (difference > 0) {
//             return { backgroundColor: 'green', color: 'white' }; // Positive difference
//         } else if (difference > -1.0) {
//             return { backgroundColor: 'lightcoral', color: 'white' }; // Negative but > -1.0
//         } else {
//             return { backgroundColor: 'red', color: 'white' }; // Difference <= -1.0
//         }
//     };

//     const getChipLabel = (difference) => {
//         return Math.abs(difference).toFixed(1); // Always show positive value, rounded to 1 decimal place
//     };


//     return (
//         <Card>
//             <Grid container xs={12} mt={2} pl={2}>
//                 <Grid container item xs={9}>
//                     <Grid item xs={4}>
//                         <Typography variant='h6'>
//                             Associate Performance
//                         </Typography>
//                     </Grid>
//                     {props?.showDateRange === false ? (
//                         <Grid item xs={4}>
//                         </Grid>
//                     ) : (<>
//                         <Grid item xs={4}>
//                             {renderDateFields()}
//                         </Grid>
//                         <Grid item xs={4}>
//                             {role === "User" ? <></> :
//                                 <IconButton
//                                     onClick={handleDownloadMenuClick}
//                                 // disabled={!isSubmittedForRange}  // Disable the button until submitted
//                                 // disabled={loading || Object.keys(errors).length !== 0}
//                                 >
//                                     <DownloadIcon />
//                                 </IconButton>}
//                             <Menu
//                                 anchorEl={menuanchorEl}
//                                 open={Boolean(menuanchorEl)}
//                                 onClose={() => { setMenuAnchorEl(null); }}
//                             >
//                                 <MenuItem
//                                     style={{ font: "small-caption" }}
//                                     onClick={() => handleDownloadPDF()}
//                                 >
//                                     Download PDF
//                                 </MenuItem>
//                                 <MenuItem
//                                     style={{ font: "small-caption" }}
//                                     onClick={() => handleDownloadExcel()}
//                                 >
//                                     Download Excel
//                                 </MenuItem>
//                             </Menu>
//                         </Grid>
//                     </>

//                     )
//                     }

//                     <Grid item xs={12}>
//                         <Stack direction={"column"}>
//                             <Typography >
//                                 Associate Name: {appraisaldata?.firstname + " " + appraisaldata?.lastname}
//                             </Typography>
//                             <Typography >
//                                 Project: {role === "Reporting Manager" ? props?.projects?.title : appraisaldata?.project}
//                             </Typography>
//                             <Typography >
//                                 {
//                                     role !== "User" ? (
//                                         <div>
//                                             Project Manager: <Link
//                                                 onClick={(e) => {
//                                                     e.preventDefault();
//                                                     navigate("/home/ratings", { state: { projectManager: appraisaldata?.projectManager } })
//                                                 }}
//                                             >{appraisaldata?.projectManager?.fullname}</Link>
//                                         </div>
//                                     ) :
//                                         (`Project Manager: ${appraisaldata?.projectManager?.fullname}`)
//                                 }
//                             </Typography>
//                             <Typography >
//                                 Rating: {
//                                     isSubmittedForRange ?
//                                         pastRatings?.[astId]?.finalRating
//                                         : (totalRatingSum / 11).toFixed(2)
//                                 }
//                             </Typography>
//                         </Stack>
//                     </Grid>
//                 </Grid>
//                 <Grid item xs={3}>
//                     <Line data={chartData} options={options} />
//                 </Grid>

//             </Grid>
//             <CardContent>
//                 <TableContainer id='content-to-print'>
//                     <Table >
//                         {OldTableheader()}
                        
//                     </Table>
//                 </TableContainer>
//                 <Dialog open={isDialogOpen}>
//                     <DialogTitle>Confirm</DialogTitle>
//                     <DialogContent>
//                         The total rating given is
//                         <Typography variant='h6'>
//                             {(totalRatingSum / 11).toFixed(2)}.
//                         </Typography>

//                         The ratings submitted are unable to change once submitted. Do you want to continue?
//                     </DialogContent>
//                     <DialogActions>
//                         <Button sx={{ color: 'white' }} onClick={() => { setIsDialogOpen(false) }}>cancel</Button>
//                         <Button sx={{ color: 'white' }} onClick={handleClick}>Ok</Button>
//                     </DialogActions>
//                 </Dialog>

//                 <TextField
//                     component="form"
//                     required={true}
//                     value={comments}
//                     disabled={isSubmittedForRange}
//                     fullWidth type='text' multiline='true' rows='2'
//                     placeholder='Write your feedback here...'
//                     sx={{ mt: 2 }}
//                     InputLabelProps={{ shrink: true }}
//                     InputProps={{
//                         sx: {
//                             fontSize: '14px',
//                             '& .MuiInputBase-input::placeholder': {
//                                 fontSize: '14px',
//                             },
//                         },
//                     }}
//                     onChange={(e) => setComments(e.target.value)}>
//                 </TextField>
//             </CardContent>

//             <CardActions sx={{ justifyContent: "flex-end" }} >

//                 {
//                     submitloading ? (
//                         <CircularProgress />
//                     ) : (
//                         !isSubmittedForRange && !submitted ? (
//                             <Grid>
//                                 <Button
//                                     size='small'
//                                     component="form"
//                                     onClick={() => {
//                                         if (validateRatings()) {
//                                             if (!comments) {
//                                                 setSeverity('error');
//                                                 setMessage("Please fill feedback for associate.");
//                                                 setSnackbarOpen(true);
//                                                 return;
//                                             }
//                                             setIsDialogOpen(true);
//                                         }
//                                     }}
//                                     sx={{ color: 'white', marginRight: '10px' }}
//                                 >
//                                     Submit
//                                 </Button>
//                             </Grid>
//                         ) : null
//                     )
//                 }

//             </CardActions>
//             <Snackbar
//                 open={snackbarOpen}
//                 onClose={() => setSnackbarOpen(false)}
//                 anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
//                 autoHideDuration={3000}>
//                 <Alert onClose={() => setSnackbarOpen(false)} severity={severity} sx={{ width: '100%' }}>
//                     {message}
//                 </Alert>
//             </Snackbar>

//         </Card >
//     )
// }

// export default UserAppraisal;
