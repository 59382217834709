export const OldUserAppraisal = [
    {
        serialNo: 1,
        Objective: 'Project',
        subObjective: [
            { desc: 'Capability', matrixTarget: 'Delivered the assigned task/assignment on time.' },
            { matrixTarget: 'Delivery with proper documentation.' },
            { matrixTarget: 'Business Solution & improvements suggested' },
            { matrixTarget: 'Helping other team members/ projects beyond assigned tasks' },
            { desc: '', matrixTarget: 'Timely Communication and follow-up' },
            { desc: '', matrixTarget: 'Quality of Work' },
        ],
        rating: [
            { sno: 1, ratingValue: 1 },
            { sno: 2, ratingValue: 3 }
        ]
    },
    {
        serialNo: 2,
        Objective: 'Exceptional Contribution',
        subObjective: [
            { desc: 'Creativity', matrixTarget: 'Exceptional work done in the project or for practice or for an organization that resulted improvement in Business process, and example reduction in cost, usage of new technology, time saving or generated a reusable component' },
        ],
        rating: [1, 2, 3, 4, 5, 6]
    },
    {
        serialNo: 3,
        Objective: 'Trainings & Talent Development',
        subObjective: [
            { desc: 'Collaboration', matrixTarget: 'Learn atleast one new concept that is aligned to Quarterly learning goal' },
        ],
        rating: [
            { sno: 1, ratingValue: 4 }
        ]
    },
    {
        serialNo: 4,
        Objective: 'JIRA and Emportal',
        subObjective: [
            { desc: 'Compliance', matrixTarget: 'Timely creation/updation of JIRA Stories and tasks' },
            { desc: '', matrixTarget: 'Timely submission of Weekly Emportal timesheets' },
            { desc: '', matrixTarget: 'Timely submission of leaves and remote clock-in in Keka' },
        ],
        rating: [1, 2, 3]
    },

]
